.image-with-text-and-bg-color-block {
    $self: &;
    max-width: $max-bg-width;
    margin: 0 auto;

    &__container {
        @include container-with-double-padding;
        display: flex;
        flex-direction: column;
        padding-top: $block-margin-mobile;
        padding-bottom: $block-margin-mobile;

        #{$self}_image-position_left & {
            flex-direction: column-reverse;
        }

        @include breakpoint(small) {
            flex-direction: row;
            padding-top: $spacing-extralarge;
            padding-bottom: $spacing-extralarge;

            #{$self}_image-position_left & {
                flex-direction: row-reverse;
            }
        }

        @include breakpoint(medium) {
            padding-left: 120px;
            padding-right: 120px;
        }
    }

    &__image-container,
    &__text-container {
        @include breakpoint(small) {
            flex: 0 0 50%;
        }
    }

    &__text-container {
        @include breakpoint(medium) {
            #{$self}_image-position_left & {
                padding-left: 10px;
            }

            #{$self}_image-position_right & {
                padding-right: 10px;
            }
        }
    }

    &__image-container {
        padding: 0;
        margin: 0;

        #{$self}_image-position_left & {
            margin-bottom: 64px;
        }

        #{$self}_image-position_right & {
            margin-top: 64px;
        }

        @include breakpoint(small) {
            #{$self}_image-position_left & {
                padding-right: 68px;
                margin-bottom: 0;
            }

            #{$self}_image-position_right & {
                padding-left: 68px;
                margin-top: 0;
            }
        }

        @include breakpoint(large) {
            #{$self}_image-position_left & {
                padding-right: 133px;

            }

            #{$self}_image-position_right & {
                padding-left: 133px;
            }
        }
    }

    &__heading {
        margin: 0 0 32px;
        @include font-size(36px, 40px);

        @include breakpoint(medium) {
            margin-bottom: 38px;
            @include font-size(40px, 48px);
        }
    }

    &__image-caption {
        @include font-size(16px, 24px);
        padding: 16px 0 0 92px;

        @include breakpoint(medium) {
            padding-top: 26px;
            #{$self}_image-position_left & {
                padding-left: 36px;
            }

            #{$self}_image-position_right & {
                @include breakpoint(medium) {
                    padding-left: 88px;
                }
            }
        }
    }

}
