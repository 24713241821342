/*Image sizes*/
@use 'sass:math';

@mixin fixed-proportion-image($width, $height) {
	height: 0px;
	padding-bottom: math.div($height, $width) * 100%;
	position: relative;

	.image__img {
		@include object-fit(cover, center);
		position: absolute;
	}
}

@mixin fixed-height-image($height) {
	height: ($height) + px;
	position: relative;
	background-position: center;
	background-size: cover;
	width: 100%;

	.image__img {
		@include object-fit(cover, center);
	}
}

@mixin fluid-image() {
	padding: 0px;
	@include full-size;
	background-position: center;
	background-size: cover;
	max-width: none;
	position: absolute !important;
    height: auto !important;

	.image__img {
		opacity: 0;
	}
}

@mixin original-proportions-image($width, $height) {
	padding: 0;
	height: auto;

	.image__img {
		position: relative;
		height: auto;
		width: #{$width}px;
		max-width: 100%;
		max-height: calc(#{$height}px * 3); // Max height multiplied by 3 to avoid stretched image sizes
	}

	&.image_in-ie .image__img {
		display: block;
	}
}

@mixin logo-image($width, $height) {
	padding: 0;
	height: auto;

	.image__img {
		position: relative !important;
		height: auto;
		width: #{$width}px;
		max-width: 100%;
		max-height: #{$height}px;
	}

	&.image_in-ie .image__img {
		display: block;
	}
}