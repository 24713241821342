$hover-animation-speed: 450ms;

.story-teaser {
    $self: &;
    display: block;
    width: 100%;
    text-decoration: none;
    @include focus-outline;

    &_size_medium {
        &:not(:last-child) {
            margin-bottom: 44px;

            @include breakpoint(small) {
                margin-bottom: 0;
            }
        }
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 198px;

        #{$self}_size_large & {
            margin: 0 -#{$spacing-xmedium};

            @include breakpoint(small) {
                margin: 0;
            }

            @include breakpoint(large) {
                padding-right: 25.5%;
            }
        }

        #{$self}_size_medium & {
            @include breakpoint(small) {
                padding-right: 13.5%;
            }
        }

        #{$self}_horizontal & {
            flex-direction: row;
        }

        #{$self}_size_small & {
            @include breakpoint(small) {
                min-height: 100%;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        transition: color $hover-animation-speed;
        margin-left: $spacing-xmedium;
        margin-top: -86px;

        &-inner {
            display: flex;
            flex-direction: column;
            flex: 1;
            position: relative;
            padding: 16px $spacing-xmedium 28px;
            height: 100%;
            word-wrap: break-word;

            &:after {
                content: '';
                display: block;
                @include full-size;
                left: 100%;
                background-color: $primary-blue;
                transition: left $hover-animation-speed;
    
                #{$self}:hover &,
                #{$self}:focus-visible &,
                #{$self}:active & {
                    left: 0;
                }
            }

            @include breakpoint(small) {
                padding: 32px 44px 44px;
            }

            #{$self}_size_small & {
                padding: 24px 20px 22px 16px;
    
                @include breakpoint(small) {
                    padding: 16px 16px 22px;
                }
            }

            #{$self}_size_medium & {
                padding: $spacing-xmedium $spacing-xmedium 20px;
    
                @include breakpoint(small) {
                    padding: 32px 32px 30px;
                }
            }
        }

        @include breakpoint(small) {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-top: 0;
            display: flex;
            flex-direction: column;

            #{$self}_size_large & {
                margin-top: -86px;
                width: 648px;
                position: relative;
                align-self: flex-end;
            }
        }

        @include breakpoint(medium) {
            #{$self}_size_large & {
                margin-top: -130px;
            }
        }

        @include breakpoint(large) {
            max-height: 100%;
            overflow-y: auto;

            #{$self}_size_large & {
                position: absolute;
                margin-top: 0;
            }
        }

        #{$self}_size_small & {
            position: relative;
            margin-left: 0;
            margin-top: 0;
        }

        #{$self}_size_medium & {
            min-height: 223px;
            margin-left: 68px;
            margin-top: -66px;

            @include breakpoint(small) {
                width: 312px;
                min-height: 300px;
                max-width: 100%;
            }
        }

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            color: $white;
        }

        
    }

    &__icon {
        width: 32px;
        height: 32px;
        margin-left: 0;
        margin-top: auto;
        transition: margin-left calc($hover-animation-speed / 1.8);
        position: relative;
        z-index: 2;

        @include breakpoint(small) {
            width: 38px;
            height: 38px;
        }

        #{$self}_size_small & {
            width: 22px;
            height: 22px;
        }

        #{$self}_size_medium & {
            width: 28px;
            height: 28px;

            @include breakpoint(small) {
                width: 32px;
                height: 32px;
            }
        }

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            margin-left: 40px;
        }
    }

    &__image {
        transition: transform calc($hover-animation-speed / 1.3);

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            transform: scale(1.05);
        }

        &-holder {
            width: 100%;
            overflow: hidden;
            position: relative;

            &_no-image {
                @extend .image_size_story-teaser-large;
            }

            #{$self}_horizontal & {
                width: 40.5%;
            }
        }
    }

    &__category {
        text-transform: uppercase;
        letter-spacing: 0.04em;
        @include font-size(18px, 26px);
        position: relative;
        z-index: 2;
    }

    &__heading {
        position: relative;
        z-index: 2;
        margin: 28px 0 30px;
        font-size: 36px;
        line-height: 42px;
        font-weight: $font-weight-bold;
        transition: color $hover-animation-speed;

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            color: $white;
        }

        @include breakpoint(medium) {
            margin: 20px 0 52px;
            @include font-size(52px, 58px);
        }

        #{$self}_size_small & {
            margin: 8px 0;
            @include font-size(20px, 28px);

            @include breakpoint(small) {
                margin: 16px 0 60px;
            }
        }

        #{$self}_size_medium & {
            margin: 7px 0 17px;
            @include font-size(28px, 34px);

            @include breakpoint(small) {
                margin: 22px 0 44px;
                @include font-size(32px, 38px);
            }
        }

        &-text {
            box-shadow: inset 0 -2px 0 $primary-blue, 0 0px 0 $primary-blue;
            transition: box-shadow calc($hover-animation-speed / 1.8);

            @include breakpoint(small) {
                box-shadow: inset 0 -3px 0 $primary-blue, 0 0px 0 $primary-blue;
            }

            #{$self}:hover &,
            #{$self}:focus-visible &,
            #{$self}:active & {
                box-shadow: inset 0 -3px 0 $white, 0 0px 0 $white;

                @include breakpoint(small) {
                    box-shadow: inset 0 -4px 0 $white, 0 0px 0 $white;
                }
            }
        }
    }
}