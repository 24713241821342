@mixin page-hero-background($bgColor: $light-red) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $bgColor;

    @include breakpoint(large) {
        left: $spacing-xlarge;
        width: calc(100% - #{$spacing-xlarge});
    }

    @include breakpoint(full-hd) {
        @include horizontal-align(absolute);
        margin-left: 0;
        max-width: $max-bg-width;
    }
}

@mixin page-hero-background-animated {
    top: -100%;
    transition: top var(--page-hero-bg-fade-animation-duration), height 500ms, left 500ms, max-width 500ms, width 500ms;

    &_visible {
        top: 0;
    }
}

@mixin page-hero-image-overlay-animated($startPosition: 100%) {
    transform: translateX($startPosition);
    transition: transform var(--page-hero-bg-fade-animation-duration);

    &_visible {
        transform: translateX(0);
    }
}

@mixin page-hero-centered-overlay($width, $height) {
    @include fixed-proportion($width, $height);
    @include center-align;
    position: absolute;
    height: 0;
    width: 100%;
}

@mixin page-hero-expandable-overlay($width, $originalHeight, $tightHeight) {
    position: relative;
    height: 0;
    @include fixed-proportion($width, $originalHeight);
    overflow: hidden;
    transition: transform var(--page-hero-bg-fade-animation-duration), padding-bottom var(--page-hero-expansion-animation-duration);

    &_tight {
        @include fixed-proportion($width, $tightHeight);
    }
}

@mixin page-hero-container {
    @include container;
    padding-bottom: $spacing-xlarge;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    max-width: $max-width;
    margin-right: 0;

    @include breakpoint(small) {
        flex-direction: row;
        padding-left: $spacing-xmedium;
    }

    @include breakpoint(large, max) {
        padding-left: $spacing-xmedium;
    }

    @include breakpoint(large) {
        margin-right: auto;
        padding-left: $spacing-giga;
    }

    @include breakpoint($max-width-l) {
        padding-left: $spacing-xlarge;
    }
}

@mixin page-hero-content-fade-animated {
    opacity: 0;
    transition: opacity var(--page-hero-content-fade-animation-duration), padding-top var(--page-hero-expansion-animation-duration), padding-bottom var(--page-hero-expansion-animation-duration);

    &_visible {
        opacity: 1;
    }
}

@mixin page-hero-content-column {
    flex: 1;

    @include breakpoint(small) {
        padding-right: $spacing-xmedium;
    }

    @include breakpoint(ipad-pro) {
        padding-right: 46px;
    }
}

@mixin page-hero-image-column {
    position: relative;
    margin-right: -$spacing-xmedium;
    margin-bottom: 43px;
    overflow: hidden;

    @include breakpoint(small) {
        margin-bottom: 0;
        width: 51.5%;
    }

    @include breakpoint(medium) {
        margin-right: -$spacing-xlarge;
    }

    @include breakpoint(large) {
        margin-right: 0;
    }
}