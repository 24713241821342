@import "../../shared-components-styles/article-page-mixins.scss";

.rich-text-block {
    clear: both;
    @include article-body;
    margin-top: 0;
    margin-bottom: 0;

    @include breakpoint(medium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}