$item-gap: $spacing-xmedium;
$article-teaser-width: calc((100% - #{$item-gap}*3) / 3);
$article-teaser-width-half: calc((100% - #{$item-gap}*2) / 2);
$article-teaser-bottom-indent: 34px;

.article-teasers-block {
    $self: &;
    @include container;
    max-width: 1312px;
    @include rtlAlignment;

    &__heading,
    &__intro{
        max-width: $max-text-width;
    }

    &__heading{
        @include font-size(36px, 38px);
        margin: 0 0 22px;

        @include breakpoint(medium) {
            @include font-size(52px, 56px);
            margin-bottom: 28px;
        }
    }

    &__intro{
        margin: 0 0 48px;
        @include breakpoint(small){
            margin-bottom: $spacing-xlarge;
            margin: 0 0 65px;
        }
    }

    &__items{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: -$article-teaser-bottom-indent;

        @include breakpoint(small){
            margin-left: -#{$item-gap};
        }
    }

    &__item{
        flex-basis: 100%;
        position: relative;
        margin: 0 0 $article-teaser-bottom-indent;

        @include breakpoint(small){
            flex-basis: $article-teaser-width;
            max-width: $article-teaser-width;
            margin-left: $item-gap;
            margin-bottom: $spacing-large;

            &_width_half{
                flex-basis: $article-teaser-width-half;
                max-width: $article-teaser-width-half;
            }

            &_width_full{
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}