@import "../../shared-components-styles/page-hero-mixins.scss";

$long-article-page-hero-heading-bottom-indent: 24px;
$long-article-page-hero-content-top-indent: 104px;
$long-article-page-hero-content-bottom-indent-mobile: 46px;
$long-article-page-hero-all-stories-link-bottom-indent: 36px;

.long-article-page-hero {
    $self: &;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    margin: auto;
    max-width: $max-bg-width;
    width: 100%;
    max-height: var(--page-hero-expanding-layer-max-height);
    transition: min-height var(--page-hero-expansion-animation-duration);
    overflow: hidden;

    @media screen and (min-width: 64em) {
        min-height: 1000px;
    }

    @media screen and (min-width: 64em) and (max-height: 1173px) {
        min-height: 100vh;
    }

    &_tight {
        min-height: 750px;
    }

    &_focused {
        min-height: var(--page-hero-expanding-layer-max-height);
    }

    @include breakpoint(medium) {
        display: block;
    }

    &__image {
        transition: transform var(--page-hero-expansion-animation-duration);
        will-change: transform;

        #{$self}_focused & {
            @include breakpoint(medium) {
                transform: scale(1.05);
            }
        }

        &-holder {
            height: 600px;
            position: relative;
            transition: transform var(--page-hero-bg-fade-animation-duration);
    
            @include breakpoint(medium) {
                @include full-size;
                height: auto;
                transform: translateY(-100%);
            }
    
            &_visible {
                transform: translateY(0);
            }
        }

        &-centered-overlay {
            @include vertical-align;
            height: 100%;
            width: 100%;

            @media screen and (min-width: 64em) {
                min-height: 1000px;
            }
        
            @media screen and (min-width: 64em) and (max-height: 1173px) {
                min-height: 100vh;
            }
        }
    }

    &__heading {
        transition: margin-bottom var(--page-hero-expansion-animation-duration);

        body & {
            margin-bottom: $long-article-page-hero-heading-bottom-indent;

            @include breakpoint(medium) {
                margin-bottom: $spacing-large;
            } 
        }

        #{$self}__content_tall & {
            @include breakpoint(medium, max) {
                margin-bottom: calc($long-article-page-hero-heading-bottom-indent + $long-article-page-hero-heading-bottom-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__container {
        display: flex;
        justify-content: flex-end;
        @include container;

        @include breakpoint(medium, max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__background {
        @include full-size;

        @include breakpoint(medium, max) {
            height: 100%;
            @include page-hero-background-animated;
        }
    }

    &__content {
        position: relative;
        padding: $long-article-page-hero-content-top-indent $spacing-xmedium $long-article-page-hero-content-bottom-indent-mobile 27px;
        transition: transform var(--page-hero-bg-fade-animation-duration), padding-top var(--page-hero-expansion-animation-duration), padding-bottom var(--page-hero-expansion-animation-duration);

        @include breakpoint(medium) {
            max-width: 880px;
            padding: $long-article-page-hero-content-top-indent 64px 64px 64px;
            transform: translateX(100%);
            margin-bottom: $spacing-xlarge;
        }

        &_tall {
            @include breakpoint(medium, max) {
                padding-top: calc($long-article-page-hero-content-top-indent + $long-article-page-hero-content-top-indent * $page-hero-content-expansion-multiplier);
                padding-bottom: calc($long-article-page-hero-content-bottom-indent-mobile + $long-article-page-hero-content-bottom-indent-mobile * $page-hero-content-expansion-multiplier);
            }
        }

        &_tall {
            @include breakpoint(medium, max) {
                padding-top: calc($long-article-page-hero-content-top-indent + $long-article-page-hero-content-top-indent * $page-hero-content-expansion-multiplier);
                padding-bottom: calc($long-article-page-hero-content-bottom-indent-mobile + $long-article-page-hero-content-bottom-indent-mobile * $page-hero-content-expansion-multiplier);
            }
        }

        &_visible {
            transform: translateX(0);
        }

        &_hidden-by-scroll {
            @include breakpoint(medium) {
                transform: translateY(-100%);
            }
        }

        &-wrapper {
            position: relative;
            overflow: hidden;
        }

        &-fade-section {
            position: relative;

            @include breakpoint(medium, max) {
                @include page-hero-content-fade-animated;
            }
        }
    }

    &__all-stories-link {
        margin-bottom: $long-article-page-hero-all-stories-link-bottom-indent;

        @include breakpoint(medium) {
            margin-bottom: $spacing-xlarge;
        }

        #{$self}__content_tall & {
            @include breakpoint(medium, max) {
                margin-bottom: calc($long-article-page-hero-all-stories-link-bottom-indent + $long-article-page-hero-all-stories-link-bottom-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__intro {
        padding-left: 43px;

        @include breakpoint(medium) {
            max-width: 584px;
            margin-left: auto;
            padding-left: 0;
        }
    }

    &__text-animation-container {
        @include container;
        @include horizontal-align();
        position: absolute !important;
        bottom: 60px;
        opacity: 0;
        transition: opacity var(--page-hero-bg-fade-animation-duration);

        &_visible {
            opacity: 1;
        }
    }

    &__animated-heading {
        body & {
            @include breakpoint(small) {
                max-width: 670px;
            }

            @include breakpoint(medium) {
                @include font-size(52px, 58px);
            }
        }
    }
}