.how-to-buy-block {
    $self: &;

    &__container {
        @include container-with-double-padding;
        max-width: $max-width-l;
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;

        &:after {
            content: none;
        }

        @include breakpoint(small) {
            flex-direction: row;
            gap: $spacing-xmedium;
        }

        @include breakpoint(medium) {
            gap: 64px;
        }
    }

    &__content {
        flex: 1;
    }

    &__image {
        &-holder {
            &_no-image {
                @extend .image_size_how-to-buy-block;
            }
        }

        &-container {
            position: relative;
            overflow: hidden;
            border-left: 16px solid $primary-blue;
            border-bottom: 16px solid $primary-blue;
            background-color: $primary-blue;
            margin-left: -$spacing-xmedium;

            @include breakpoint(small) {
                flex: 0 0 48.75%;
                border-left-width: $spacing-xmedium;
                border-bottom-width: $spacing-xmedium;
                margin-left: 0;
            }
        }
    }

    &__logo {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $primary-blue;
        padding: 30px 20px 18px 14px;
        width: auto; 

        &-holder {
            background-color: $primary-blue;
            padding: $spacing-xmedium;
            position: absolute;
            padding: 42px 22px 26px;
            left: 0;
            bottom: 0;
        }
    }

    &__heading {
        @extend .h2;
        margin: 0 0 32px;
    }

    &__text {
        @include font-size(24px, 32px);
    }
}