@import "../../shared-components-styles/image-mixins.scss";
@import "../../shared-components-styles/page-hero-mixins.scss";

$content-spacing: 62px;
$content-spacing-mobile: 40px;

.front-page-hero {
    $self: &;
    position: relative;
    display: flex;
    justify-content: center;
	margin-bottom: $spacing-xxlarge;

    &__background {
        @include page-hero-background;
        @include page-hero-background-animated;
    }

    &__container {
        @include page-hero-container;
        transition: padding-bottom var(--page-hero-expansion-animation-duration);

        &_tall {
            padding-bottom: calc($spacing-xlarge + $spacing-xlarge * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                padding-bottom: calc($spacing-xxlarge + $spacing-xxlarge * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__intro {
        margin-bottom: $content-spacing-mobile;
        transition: margin-bottom var(--page-hero-expansion-animation-duration);

        @include breakpoint(medium) {
            margin-bottom: $content-spacing;
        }

        #{$self}__container_tall & {
            margin-bottom: calc($content-spacing-mobile + $content-spacing-mobile * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                margin-bottom: calc($content-spacing + $content-spacing * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__text-holder {
        max-width: 450px;
        display: flex;
        flex-direction: column;
    }

    &__content {
        @include page-hero-content-column;
        @include page-hero-content-fade-animated;
        padding-top: $spacing-large;
        @include rtlAlignment;

        @include breakpoint(medium) {
            padding-top: $spacing-xlarge;
        }

        #{$self}__container_tall & {
            padding-top: calc($spacing-large + $spacing-large * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                padding-top: calc($spacing-xlarge + $spacing-xlarge * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__image {
        position: absolute !important;
        top: 0;
        left: 0;

        &-holder {
            @include page-hero-image-column;
            margin-bottom: 0;

            &-overlay {
                @include page-hero-image-overlay-animated;
                @include page-hero-expandable-overlay(648, 580, 440);
            }
        }

        &-centered-overlay {
            @include page-hero-centered-overlay(648, 580);
        }
    }

    &__heading {
        word-break: break-word;
    }

    &__slogan {
        display: inline-flex;
        @include horizontal-align;
    }
}