$search-prefix-label-width: 100px;
$input-border-radius: 46px;

.search-form {
    $self: &;

    &__container {
        position: relative;
        border-radius: $input-border-radius;

        #{$self}_in-recipe-list &,
        #{$self}_in-search-page & {
            margin: 0 -10px;

            @include breakpoint(medium) {
                margin: 0;
            }
        }

        #{$self}_focused &,
        #{$self}_active & {
            box-shadow: 0 0 0 8px $white;
            background-color: $white;

            @include breakpoint(medium) {
                box-shadow: 0 0 0 12px $white;
            }
        }
        
    }

    &__label {
        @include breakpoint(medium) {
            margin-left: 10px;
        }
    }

    &__prefix-label {
        display: none;
        width: $search-prefix-label-width;
        padding-top: 1px;
        @include vertical-align(absolute);
        font-size: 18px;
        line-height: 26px;
        font-weight: $font-weight-bold;
        padding-right: 8px;
        left: 10px;
        @include truncate($search-prefix-label-width);
        text-align: center;

        #{$self}__input:focus ~ &, 
        #{$self}_active & {
            display: block;
        }
    }

    &__input {
        width: 100%;
        height: 62px;
        border: 2px solid $primary-blue;
        border-radius: $input-border-radius;
        padding: 0 $spacing-xlarge 3px 20px;
        font-family: $font-family;
        font-size: 24px;
        background: none;
        color: $primary-blue;
        @include focus-outline(13px);

        @include breakpoint(medium) {
            padding-left: $spacing-xmedium;
            height: 51px;
            @include focus-outline(16px);
        }

        @include placeholder {
            font-size: 20px;
            color: $primary-blue;
        }

        &:hover {
            box-shadow: inset 0 0 0 1px $primary-blue;
        }

        &:focus,
        #{$self}_active &  {
            padding-left: calc($search-prefix-label-width + 10px);
            box-shadow: 0 0 0 8px $white;
            background-color: $white;
            border: 1px solid $white;

            @include breakpoint(medium) {
                box-shadow: 0 0 0 12px $white;
            }

            &::placeholder {
                color: transparent;
            }
        }
    
        &_theme {
            &_light {
                border-color: $white;

                @include placeholder {
                    color: $soft-blue;
                }
            }
        }
    
        &_size {
            &_large {
                @include focus-outline(10px);

                @include breakpoint(medium) {
                    height: 64px;
                    @include focus-outline(13px);
                }

                &:focus,
                #{$self}_active &  {
                    box-shadow: 0 0 0 6px $white;

                    @include breakpoint(medium) {
                        height: 64px;
                        box-shadow: 0 0 0 8px $white;
                    }
                }
            }
        }
    }

    &__btn {
        @include vertical-align(absolute);
        border: none;
        background: none;
        right: 16px;
        color: $primary-blue;
        cursor: pointer;
        @include focus-outline(3px);

        &:hover {
            opacity: 0.6;
        }

        @include breakpoint(medium) {
            right: 13px;
        }
    }

    &__icon {
        color: $primary-blue;

        &_theme_light {
            color: $white;

            #{$self}_active & {
                color: $primary-blue;
            }
        }
    }
}