@import "../../shared-components-styles/article-page-mixins.scss";

.quote-block {
    $self: &;
    clear: both;
    overflow: hidden;
    @include article-body;
    margin-top: 0;
    @include rtlAlignment;

    .rich-text & {
        @include article-body;
        max-width: 100%;
        overflow: visible;
        padding: 0 !important;
        margin-top: $block-margin-mobile;
        margin-bottom: $block-margin-mobile;
    }

    .rich-text div:last-child & {
        margin-bottom: 0;
    }


    &__text {
        padding: 28px 0 0;
        font-weight: bold;
        position: relative;
        display: block;
        @include font-size(36px, 40px);

        #{$self} & {
            margin: 0 0 2rem;
        }

        @include breakpoint(medium) {
            @include font-size(52px, 56px);
        }

        @include breakpoint(small) {
            &:before {
                content: '«';
            }

            &:after {
                content: '»';
            }
        }
    }

    &__caption {
        margin: 0 0 0 92px;

        @include breakpoint(medium) {
            margin: 0 0 0 64px;
        }
        
    }

    &__author,
    &__title {
        @include font-size(18px, 26px);
    }

    &__top-line {
        display: block;
        height: 4px;
        background-color: $primary-red;
        width: calc(100% + 60px);
        position: absolute;
        top: 0;
        right: 0;
    }

    &__author {
        font-weight: bold;
    }

    &__quote {
        @include breakpoint(small-max, max) {
            display: none;
        }
    }
}