$animation-speed: 0.35s;

.recipe-teaser {
    $self: &;
    min-height: 180px;
    background-color: $orange;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: $maincolor;
    position: relative;
    width: 100%;

    &_horizontal {
        flex-direction: row;
    }
    
    @include breakpoint(small) {
        min-height: 418px;
    }

    @include focus-outline;

    &__image-holder {
        position: relative;
        z-index: 2;
        overflow: hidden;
        width: 100%;

        #{$self}_horizontal & {
            width: 44%;
    
            @include breakpoint(small) {
                width: 100%;
            }
        }

        &_no-image {
            @extend .image_size_recipe-teaser;
        }
    }

    &__image{
        transition: transform #{$animation-speed} ease-in-out;
        transform: scale(1.01);

        #{$self}:hover &,
        #{$self}:focus-visible & {
            transform: scale(1.1);
        }
    }

    &__content {
        position: relative;
        padding: 16px;
        flex: auto;
        display: flex;
        flex-direction: column;
        z-index: 1;

        #{$self}_horizontal & {
            flex: 1;
            padding: 12px;
        }

        @include breakpoint(medium) {
            padding: 16px $spacing-xmedium;
        } 

        #{$self}:hover &,
        #{$self}:focus-visible & {

            &:after {
                height: 100%;
            }
        }

        #{$self}:active &{
            &:after {
                height: 100%;
                left: -10px;
                right: -10px;
            }
        }

        &:after {
            content: "";
            display: block;
            background: $maincolor;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            transition: all #{$animation-speed} ease-in-out;
        }
    }

    &__heading {
        margin: 0 0 20px;
        color: inherit;
        @include font-size(22px, 26px);
        font-weight: 700;
        transition: color 0.05s ease-in-out;
        transition-delay: 0s;
        position: relative;
        z-index: 2;

        @include breakpoint(medium) {
            @include font-size(24px, 28px);
        }

        #{$self}:hover &,
        #{$self}:focus-visible & {
            text-decoration: underline;
            color: white;
            transition-delay: calc(#{$animation-speed}/2);
        }
        #{$self}:active &{
            color: white;
        }

        #{$self}_horizontal & {
            margin: 0 0 17px;
        }
    }

    &__details {
        margin-top: auto;
        color: inherit;
        color: inherit;
        position: relative;
        z-index: 2;

        &_invisible {
            opacity: 0;
        }

        transition: color 0s ease-in-out;
        transition-delay: calc(#{$animation-speed}/1.5);;

        #{$self}:hover &,
        #{$self}:focus-visible & {
            color: white;
            transition-delay: 0.07s;
        }
        #{$self}:active &{
            color: white;
        }
    }
}