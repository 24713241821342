@import "../../shared-components-styles/page-hero-mixins.scss";

$how-to-page-hero-container-top-indent: 180px;
$how-to-page-hero-container-bottom-indent: 64px;

$how-to-page-hero-container-top-indent-mobile: 160px;
$how-to-page-hero-container-bottom-indent-mobile: 43px;

.how-to-page-hero {
    $self: &;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;

    @include breakpoint(medium) {
        margin-bottom: $spacing-xxlarge;
    }

    &__background {
        @include page-hero-background;
        @include page-hero-background-animated;
    }

    &__container {
        @include page-hero-container;
        @include page-hero-content-fade-animated;
        display: block;

        #{$self} & {
            padding-top: $how-to-page-hero-container-top-indent-mobile;
            padding-bottom: $how-to-page-hero-container-bottom-indent-mobile;
            padding-left: 0px;
            padding-right: 0px;

            @include breakpoint(small) {
                padding-left: 24px;
                padding-right: 24px;
            }

            @include breakpoint(medium) {
                padding-top: $how-to-page-hero-container-top-indent;
                padding-bottom: $how-to-page-hero-container-bottom-indent;
                padding-left: 60px;
                padding-right: 60px;
            }

            &_tall {
                padding-top: calc($how-to-page-hero-container-top-indent-mobile + $how-to-page-hero-container-top-indent-mobile * $page-hero-content-expansion-multiplier);
                padding-bottom: calc($how-to-page-hero-container-bottom-indent-mobile + $how-to-page-hero-container-bottom-indent-mobile * $page-hero-content-expansion-multiplier);

                @include breakpoint(medium) {
                    padding-top: calc($how-to-page-hero-container-top-indent + $how-to-page-hero-container-top-indent * $page-hero-content-expansion-multiplier);
                    padding-bottom: calc($how-to-page-hero-container-bottom-indent + $how-to-page-hero-container-bottom-indent * $page-hero-content-expansion-multiplier);
                }
            }
        }
    }

    &__content {
        @include container;
        max-width: $max-width-s;
        @include rtlAlignment;
    }

    &__all-guides-link {
        margin-bottom: 43px;
    }

    &__label {
        display: block;
        margin: 0 0 8px;
        text-transform: uppercase;
        font-weight: normal;

        @include breakpoint(medium) {
            margin-bottom: 12px;
        }
    }

    &__heading {
        #{$self} & {
            margin: 0 0 48px;
            @include font-size(36px, 40px);

            @include breakpoint(medium) {
                margin-bottom: 22px;
                @include font-size(52px, 56px);
            }
        }
    }
}