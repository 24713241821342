
@font-face {
    src: url("../assets/fonts/ESPeak-Italic.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: italic;
    font-weight: $font-weight-regular;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/ESPeak-LightItalic.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: italic;
    font-weight: $font-weight-light;
    font-display: swap;
}


@font-face {
    src: url("../assets/fonts/ESPeak-MediumItalic.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: italic;
    font-weight: $font-weight-medium;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/ESPeak-BoldItalic.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: italic;
    font-weight: $font-weight-bold;
    font-display: swap;
}


@font-face {
    src: url("../assets/fonts/ESPeak-Light.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: normal;
    font-weight: $font-weight-light;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/ESPeak-Regular.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: normal;
    font-weight: $font-weight-regular;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/ESPeak-Medium.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: normal;
    font-weight: $font-weight-medium;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/ESPeak-Bold.woff2") format("woff2");
    font-family: "ESPeak";
    font-style: normal;
    font-weight: $font-weight-bold;
    font-display: swap;
}