@import "../../shared-components-styles/page-hero-mixins.scss";

$hero-container-top-indent: 104px;
$hero-container-bottom-indent: 57px;

$hero-container-top-indent-mobile: 130px;
$hero-container-bottom-indent-mobile: 40px;

$hero-content-indent: 63px;
$hero-content-indent-mobile: 32px;

.simple-page-hero {
    $self: &;
    position: relative;
    display: flex;
    justify-content: center;
	margin-bottom: 64px;

    @include breakpoint(medium) {
        margin-bottom: $spacing-xxlarge;
    }

    &__background {
        @include page-hero-background;
        @include page-hero-background-animated;
    }

    &__container {
        @include page-hero-container;        
        @include page-hero-content-fade-animated;
        display: block;

        body & {
            padding-top: $hero-container-top-indent-mobile;
            padding-bottom: $hero-container-bottom-indent-mobile;

            @include breakpoint(medium) {
                padding-top: $hero-container-top-indent;
                padding-bottom: $hero-container-bottom-indent;
            }

            &_tall {
                padding-top: calc($hero-container-top-indent-mobile + $hero-container-top-indent-mobile * $page-hero-content-expansion-multiplier);
                padding-bottom: calc($hero-container-bottom-indent-mobile + $hero-container-bottom-indent-mobile * $page-hero-content-expansion-multiplier);

                @include breakpoint(medium) {
                    padding-top: calc($hero-container-top-indent + $hero-container-top-indent * $page-hero-content-expansion-multiplier);
                    padding-bottom: calc($hero-container-bottom-indent + $hero-container-bottom-indent * $page-hero-content-expansion-multiplier);
                }
            }
        }
    }

    &__heading {
        word-break: break-word;

        #{$self} & {
            margin: 0;
            @include breakpoint(medium) {    
                max-width: 50%;
            }
        }
    }

    &__content {
        margin-top: $hero-content-indent-mobile;
        transition: margin-top var(--page-hero-expansion-animation-duration);

        @include breakpoint(medium) {
            margin-top: $hero-content-indent;
        }

        #{$self}__container_tall & {
            margin-top: calc($hero-content-indent-mobile + $hero-content-indent-mobile * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                margin-top: calc($hero-content-indent + $hero-content-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__intro{
        padding: 0 0 20px $spacing-xmedium;
        
        #{$self}__heading + &{
            margin-top: $spacing-xmedium;
        }

        @include breakpoint(medium) {
            padding: 0 0 0 44px;
            max-width: 740px;

            #{$self}__heading + &{
                margin-top: 44px;
            }
        }
    }
}