.specimen-teaser-block {
    @include container;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(small) {
        padding-left: $spacing-xmedium;
        padding-right: $spacing-xmedium;
    }

    @include breakpoint(medium) {
        padding-left: $spacing-xlarge;
        padding-right: $spacing-xlarge;
    }

    &__container {
        background-color: $light-blue;
        padding-bottom: 60px;

        @include breakpoint(medium) {
            display: flex;
            padding-bottom: 46px;
        }
    }

    &__image {
        &-holder {
            padding-left: $spacing-xmedium;

            @include breakpoint(medium) {
                flex: 0 0 49%;
                padding-left: 0;
            }
        }
    }

    &__content {
        padding: 33px $spacing-xmedium 0;
        @include rtlAlignment;

        @include breakpoint(medium) {
            flex: 1;
            padding: 42px 64px 0 68px;
        }
    }

    &__category {
        @include capitalized-label;
        margin-bottom: 8px;

        @include breakpoint(medium) {
            margin-bottom: 12px;
        }
    }

    &__heading {
        @include font-size(36px, 40px);
        margin: 0 0 40px;

        @include breakpoint(medium) {
            @include font-size(52px, 56px);
            margin: 0 0 30px;
        }
    }

    &__logo {
        flex: 0 0 72px;
        margin-right: 18px;

        @include rtlAlignment {
            margin-left: 18px;
            margin-right: 0;
        }

        @include breakpoint(medium) {
            flex: 0 0 92px;
            margin-top: 8px;
        }
    }

    &__facts {
        @include breakpoint(medium) {
            padding-left: 32px;
        }
    }

    &__fact {
        &:not(:last-child) {
            margin-bottom: 27px;

            @include breakpoint(medium) {
                margin-bottom: 16px;
            }
        }
        
        &-heading {
            display: block;
            @include font-size(18px, 26px);

            @include breakpoint(medium) {
                margin-bottom: 4px;
            }
        }

        &-logo {
            &-content {
                display: flex;
                margin-top: 13px;

                @include breakpoint(medium) {
                    margin-top: 0;
                    margin-left: -32px;
                }
            }
        }
    }

    &__slogan {
        margin: 48px 26% 0 0;
        text-align: right;
        display: none;

        @include breakpoint(medium) {
            display: block;
        }

        &_mobile {
            margin: 64px 30% 0 0;
            display: block;

            @include breakpoint(medium) {
                display: none;
            }
        }
    }

    &__button {
        &-holder {
            margin-top: 32px;

            @include breakpoint(medium) {
                margin-top: 46px;
            }
        }
    }
}