@import "../../shared-components-styles/page-hero-mixins.scss";

$recipe-page-hero-content-indent: 194px;
$recipe-page-hero-content-indent-mobile: $spacing-large;

$recipe-page-hero-container-top-indent-mobile: 180px;
$recipe-page-hero-container-bottom-indent: 44px;

$recipe-page-intro-indent: 30px;

$all-recipes-link-indent: 35px;
$all-recipes-link-indent-mobile: $spacing-large;
$all-recipes-link-top-position-mobile: 120px;

.recipe-page-hero {
    $self: &;
    margin-bottom: $spacing-xlarge;
    position: relative;

    @include breakpoint(small) {
        margin-bottom: 100px;
    }

    &__background{
        @include page-hero-background($orange);
        @include page-hero-background-animated;
    }

    &__container {
        @include container;
        padding-bottom: $recipe-page-hero-container-bottom-indent;
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 684px;
        margin-right: 0;
        transition: padding-top var(--page-hero-expansion-animation-duration), padding-bottom var(--page-hero-expansion-animation-duration);

        @include breakpoint(small) {
            flex-direction: row;
            max-width: $max-width;
            margin-right: auto;
        }

        @include breakpoint(small, $min-max: "max") {
            padding-top: $recipe-page-hero-container-top-indent-mobile;
        }

        &_tall {
            padding-bottom: calc($recipe-page-hero-container-bottom-indent + $recipe-page-hero-container-bottom-indent * $page-hero-content-expansion-multiplier);

            @include breakpoint(small, $min-max: "max") {
                padding-top: calc($recipe-page-hero-container-top-indent-mobile + $recipe-page-hero-container-top-indent-mobile * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__intro {
        margin-bottom: $recipe-page-intro-indent;
        transition: margin-bottom var(--page-hero-expansion-animation-duration);

        @include breakpoint(small, $min-max: "max") {
            padding-left: 23px;
        }

        @include breakpoint(small) {
            padding-left: 40px;
        }

        #{$self}__container_tall & {
            margin-bottom: calc($recipe-page-intro-indent + $recipe-page-intro-indent * $page-hero-content-expansion-multiplier);
        }
    }

    &__content {
        flex: 1;
        @include page-hero-content-fade-animated;
        padding-top: $recipe-page-hero-content-indent-mobile;
        @include rtlAlignment;

        @include breakpoint(small) {
            margin-left: 7.2%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: $recipe-page-hero-content-indent;
            align-self: flex-end;
        }

        #{$self}__container_tall & {
            padding-top: calc($recipe-page-hero-content-indent-mobile + $recipe-page-hero-content-indent-mobile * $page-hero-content-expansion-multiplier);

            @include breakpoint(small) {
                padding-top: calc($recipe-page-hero-content-indent + $recipe-page-hero-content-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__image {
        position: absolute !important;
        top: 0;
        left: 0;
        
        &-holder {
            margin-right: -#{$spacing-xmedium};
            overflow: hidden;

            @include breakpoint(small) {
                margin-right: 0;
                width: 51.9%;
                align-self: flex-start;
            }

            @include breakpoint(large) {
                margin-left: $spacing-xlarge;
            }

            &-overlay {
                @include page-hero-image-overlay-animated(-100%);
                @include page-hero-expandable-overlay(684, 600, 420);
            }
        }

        &-centered-overlay {
            @include page-hero-centered-overlay(684, 600);
        }
    }

    &__details {
        margin-top: auto;
    }

    &__all-recipes-link {
        margin-bottom: $all-recipes-link-indent;
        align-self: flex-start;
        transition: top var(--page-hero-expansion-animation-duration), margin-bottom var(--page-hero-expansion-animation-duration);

        @include breakpoint(small, $min-max: "max") {
            position: absolute;
            top: $all-recipes-link-top-position-mobile;
        }

        #{$self}__container_tall & {
            margin-bottom: calc($all-recipes-link-indent + $all-recipes-link-indent * $page-hero-content-expansion-multiplier);

            @include breakpoint(small, $min-max: "max") {
                top: calc($all-recipes-link-top-position-mobile + $all-recipes-link-top-position-mobile * $page-hero-content-expansion-multiplier);
            }
        }
    }
}