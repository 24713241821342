.toggle-button {
    $self: &;
    display: inline-block;
    position: relative;
    text-align: left;
    border: none;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    background: none;
    padding: 0;
    @include focus-outline;

    &__text {
        flex: 1;
        position: relative;
        vertical-align: middle;
        @include font-size(20px, 28px);
        z-index: 1;
        transition: box-shadow 200ms;
        @include underlined-link(2px);

        #{$self}:hover &,
        #{$self}:active &,
        #{$self}_active & {
            box-shadow: inset 0 -1px 0 $primary-blue, 0 2px 0 $primary-blue;
        }

        #{$self}:active &,
        #{$self}_active & {
            @include underlined-link-with-bg;
        }

        &_theme-light {
            @include underlined-link(2px, $white);

            #{$self}:hover &,
            #{$self}:active &,
            #{$self}_active &  & {
                box-shadow: inset 0 -1px 0 $white, 0 2px 0 $white;
            }

            #{$self}:active &,
            #{$self}_active & {
                @include underlined-link-with-bg($primary-blue);
            }
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        color: $primary-blue;
        transition: all 350ms;

        &_prefix {
            margin-right: 16px;

            @include rtlAlignment {
                margin-left: 16px;
                margin-right: 0;
            }
        }

        &_suffix {
            margin-left: 10px;
            margin-top: 2px;

            @include rtlAlignment {
                margin-left: 0;
                margin-right: 10px;
            }
        }

        #{$self}_theme-light & {
            color: $white;
        }
    }
}