.recipe-details {
    $self: &;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;


    &__item {
        margin-right: 30px;
        margin-top: 15px;

        @include rtlAlignment {
            margin-left: 30px;
            margin-right: 0;
        };

        @include breakpoint(small) {
            margin-right: 40px;
            margin-top: 10px;

            @include rtlAlignment {
                margin-left: 40px;
                margin-right: 0;
            };
        }

        #{$self}_small & {
            margin-right: 25px;
            margin-top: 15px;

            @include rtlAlignment {
                margin-left: 25px;
                margin-right: 0;
            };

            @include breakpoint(small) {
                margin-right: 11px;

                @include rtlAlignment {
                    margin-left: 11px;
                    margin-right: 0;
                };
            }
        }
    }
}