@import "../../shared-components-styles/page-hero-mixins.scss";

.not-found-page {
    $self: &;
    position: relative;
    display: flex;
    justify-content: center;

    &__background {
        @include page-hero-background;
        background-color: $orange;
    }

    &__container {
        @include page-hero-container;
        display: flex;
        justify-content: flex-end;

        #{$self} & {
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 130px;

            @include breakpoint(medium) {
                padding-bottom: 150px;
                padding-right: $spacing-xlarge;
            }
        }
    }

    &__content {
        background-color: $white;
        max-width: 816px;
        padding: 176px 30px 80px;
        @include rtlAlignment;

        @include breakpoint(medium) {
            padding: 236px 66px $spacing-xlarge;
        }
    }

    &__heading {
        @include font-size(36px, 40px);
        margin: 0 0 44px;

        @include breakpoint(medium) {
            @include font-size(52px, 56px);
            margin: 0 0 65px;
        }
    }

    &__btn {
        &-holder {
            margin-top: $spacing-xlarge;

            @include breakpoint(medium) {
                margin-top: 64px;
            }
        }
    }
}