.page-heading {
    @include font-size(46px, 52px);
	margin-bottom: $spacing-xmedium;
	margin-top: 0;

	&_small{
		@include font-size(36px, 40px);
		margin-bottom: 24px;
		letter-spacing: -0.3px;	
	}

	@include breakpoint(small) {
		margin-bottom: 44px;

		&_small{
			margin-bottom: 26px;
		}
	}

	@include breakpoint(medium) {
		@include font-size(90px, 94px);
		margin-bottom: 64px;
		letter-spacing: 0;	

		&_small{
			@include font-size(52px, 56px);
			margin-bottom: 26px;
		}
	}
}