@import "../shared-components-styles/content-item-mixins.scss";

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.mouse-user * {
	outline: none;
}

html,
body {
	font-size: 100%;
	margin: 0;
	overflow-x: hidden;
	background: $website-bg-color;
	height: 100%;

	@if $enable-font-smoothing ==true {
		font-smooth: always;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
	}
}

html {
	overflow: visible;
	-webkit-tap-highlight-color: transparent;
	line-height: 1em;

	&[dir="rtl"] {
		direction: rtl;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll !important;
	min-width: $min-width;
	font-family: $font-family;
	font-size: $font-size-em;
	font-weight: $font-weight-regular;
	line-height: $line-height;
	-webkit-overflow-scrolling: touch;
	color: $primary-blue;

	@include breakpoint(medium, max) {
		&.header-menu-is-opened {
			overflow: hidden !important;
		}
	}
}

.mouse-user *:focus {
	outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input, select, form {
	@include rtlAlignment;
}

.container {
	@include container;

	&_small {
		@extend .container;
		max-width: $max-width-s;
	}
}

.clearfix {
	@extend %clear;
}

.block {
	overflow: hidden;
	width: 100%;
	margin: 0 0 $spacing-large;

	@include breakpoint(medium) {
		margin: 0 0 $spacing-xxlarge;
	}
}

.page {
	$self: &;

	&__root {
		width: 100%;
		display: table;
		height: 100vh;
		width: 100%;
		table-layout: fixed;

		&.on-page-editing {
			height: auto;
		}
	}

	&__header {
		position: relative;
		z-index: 100;
	}

	&__container {
		@extend .container;
	}

	&__footer {
		display: table-row;
		height: 1px;
		position: relative;

		@media print {
			display: none;
		}
	}

	body &__content-item {
		@include content-item;
	}
}

.todo {
	position: relative;
	overflow: hidden;
	border: 1px solid red;
	padding: 0px;


	&:before {
		content: attr(data-todo-text);
		float: right;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-color: red;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAOCAMAAABuIH5JAAAAMFBMVEX///////////////////////////////////////////////////////////////9Or7hAAAAAD3RSTlMAESIzRFVmd4iZqrvM3e5GKvWZAAAAuUlEQVR4AbWRy47FMAhDyePekEfw///tQF1VqWY1i/Gisg7GqIr8kzYoE2nhR3ZD1HycNJxKqKkubFWV6Tk1WJERYEUi+bZ2YCUWD6zYiZqY7RssmDtUkQr0Mzo9w41K8HVnGHLN7IySRsOHwN11ifvpd7T4lKC8ouWIbkzhWba6e6L93dq5OWDpAXnzB7aDI5oNK4sCSuClKz5T0vRLd5SnP4ZQD0DV5zW+QmUtNE1V8wVCjTCs/E0/qlsMpWWH2kkAAAAASUVORK5CYII=);
		position: relative;
		z-index: 101;
		min-height: 16px;
		color: #fff;
		padding-left: 46px;
		font-size: 14px;
		line-height: 14px;
	}

	&:after {
		display: block;
		content: " ";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba(255, 0, 0, 0.3);
	}
}

.hidden {
	display: none;
}