@import "../../shared-components-styles/teasers-block-mixins.scss";

$teaser-margin: $spacing-xmedium;
$teaser-width-desktop: calc(25% - (#{$spacing-xmedium} * 3 / 4));

.recipe-teasers-block {
    $self: &;
    @include container;
    overflow: hidden;
    @include rtlAlignment;

    &__heading {
        @include teasers-block-heading;

        @include breakpoint($ipad-pro-breakpoint){
            margin-bottom: $spacing-extralarge;
        }
    }

    &__items {
        list-style: none;
        display: none;

        @include breakpoint($ipad-pro-breakpoint) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-bottom: 45px;
        }
    }

    &__item {
        flex: $teaser-width-desktop;
        max-width: $teaser-width-desktop;
        margin-left: $teaser-margin;

        &:first-child {
            margin-left: 0;
        }

        @include rtlAlignment {
            margin-right: $teaser-margin;
            margin-left: 0;

            &:first-child {
                margin-right: 0;
            }
        }

        #{$self}__tesaer {
            height: 100%;
        }
    }

    &__all-species-link {
        @include teasers-block-more-link;
    }

    &__slider {
        padding-bottom: 110px;
        margin-left: 0px;

        @include breakpoint($ipad-pro-breakpoint) {
            display: none !important;
        }

        &-nav-button {
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
            width: 48px;
            height: 48px;
            transition: transform 250ms;
            transform: scale(1);

            &_prev {
                right: 55px;
            }

            &_disabled {
                transform: scale(0);
            }
        }

        .slick-slide {
            padding: 0 12px 0 0;

            &:nth-child(even){
                transform: translateY(38px);
            }
        }

        .slick-list {
            overflow: visible;
            margin: 0 22.5% 0 -6px;
        }

        &-paging {
            list-style: none;
            position: absolute;
            bottom: 0;
            left: 0px;
            display: block;

            &>li {
                float: left;
                margin: 0 12px 17px 0;
                padding: 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-paging-button {
            display: block;
            width: 16px;
            height: 16px;
            padding: 0;
            margin: 0;
            border: 1px solid $maincolor;
            background: white;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 50%;
            cursor: pointer;
            transition: all 200ms;
            @include focus-outline;

            &:hover,
            &:focus-visible {
                transform: scale(1.15);
            }

            &_active {
                background: $maincolor;
            }
        }

        #{$self}__tesaer {
            &:focus-visible {
                outline: none;
            }
        }
    }
}