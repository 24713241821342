.action-button {
    $self: &;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: $maincolor;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @include focus-outline;

    &:last-child {
        margin-bottom: 0;
    }

    @include breakpoint(small) {
        margin: 0;
    }

    &__icon {
        margin-right: 12px;
        align-self: center;

        @include rtlAlignment {
            margin-left: 12px;
            margin-right: 0;
        }
    }

    &__text {
        display: block;
        @include underlined-link;
        align-self: center;
        @include font-size(20px, 28px);

        @include breakpoint(medium) {
            @include font-size(22px, 26px);
        }

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            @include underlined-link-with-bg;
        }
    }
}