@mixin list-base {
	margin: 20px 0 32px;
	
	@include breakpoint(medium) {
		margin-bottom: 44px;
	}

	li {
		padding: 0 0 0 30px;
		margin-bottom: 32px;
		position: relative;
		list-style: none;
		@media print {
			list-style: disc;
		}

		@include rtlAlignment {
			padding: 0 30px 0 0;
		}

		@include breakpoint(medium) {
			padding-left: 46px;
			margin-bottom: 44px;

			@include rtlAlignment {
				padding-right: 46px;
				padding-left: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			@media print {
				content: none;
			}

			@include rtlAlignment {
				left: auto;
				right: 0px;
			}
		}
	}
}

@mixin bullet-list {
	@include list-base;

	li:before {
		width: 14px;
		height: 14px;
		background: $orange;
		border-radius: 50%;
		overflow: hidden;
		top: 8px;
		left: 0px;
	}
}

@mixin ordered-list {
	@include list-base;

	li{
		counter-increment: my-ol-counter;

		&:before {
			content: counter(my-ol-counter);
			font-weight: bold;
			line-height: inherit;
			color: $orange;
		}
	}
}

@mixin list-base-default {
	margin: 48px 0;

	li {
		padding: 0 0 0 3px;
		margin-bottom: 8px;
		position: relative;
		list-style-type: none;
		list-style-position: inside;

		&:before {
			content: none;
		}

		@media print {
			list-style-type: disc;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}


@mixin bullet-list-default {
	@include list-base-default;

	li {
		list-style-type: disc;
		padding: 0 0 0 8px;
	}
}

@mixin ordered-list-default {
	@include list-base-default;

	li {
		list-style-type: decimal;
	}
}