@mixin search-teaser {
    position: relative;
    display: block;
    color: $primary-blue;
    text-decoration: none;
    @include focus-outline;
}

@mixin search-teaser-category {
    @include capitalized-label;
    margin-bottom: 8px;
}

@mixin search-teaser-content {
    padding: 17px $spacing-xmedium $spacing-xmedium 12px;

    @include breakpoint(medium) {
        padding: 17px $spacing-xmedium $spacing-xmedium;
    }
}

@mixin search-teaser-container {
    position: relative;
    display: flex;
    min-height: 198px;

    @include breakpoint(small) {
        display: block;
    }
}

@mixin search-teaser-image-aligner {
    padding-top: 15px;
    flex: 0 0 44%;

    @include breakpoint(small) {
        padding-top: 0;
        padding-left: $spacing-xmedium;
        flex: none;
    }
}