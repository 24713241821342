$container-mobile-top-padding: $block-margin-mobile;
$container-desktop-top-padding: $spacing-xxlarge;

@mixin footer-background {
    background-color: $light-red;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;

    @include breakpoint(large) {
        left: $spacing-xlarge;
        width: calc(100% - #{$spacing-xlarge});
    }

    @include breakpoint(full-hd) {
        @include horizontal-align(absolute);
        margin-left: 0;
        max-width: $max-bg-width;
    }
}

@mixin footer-container {
    position: relative;
    @include container;
    padding-top: $container-mobile-top-padding;
    padding-bottom: $spacing-xxlarge;
    max-width: $max-width;
    margin-right: 0;

    @include breakpoint(medium) {
        padding: $container-desktop-top-padding $spacing-xmedium $spacing-xxlarge;
    }

    @include breakpoint(large, max) {
        padding-left: $spacing-xmedium;
    }

    @include breakpoint(large) {
        margin-right: auto;
        padding-left: $spacing-giga;
        padding-right: $spacing-xlarge;
    }

    @include breakpoint($max-width-l) {
        padding-left: $spacing-xlarge;
    }
}