$container-width-mobile: calc(#{$max-width-s} - (#{$spacing-xmedium} *2));
$container-width-desktop: calc(#{$max-width-s} - (#{$spacing-xlarge} *2));

.image-block {
    $self: &;
    @extend .container_small;

    &_position_left,
    &_position_right {
        @include breakpoint(small) {
            @include container-with-double-padding;
        }
    }

    &__container {
        padding: 0;
        margin: 0;

        #{$self}_position_left &,
        #{$self}_position_right & {
            width: 100%;

            @media (min-width: $max-width-s-with-paddings) {
                max-width: calc(#{$container-width-mobile} + (100% - #{$container-width-mobile}) / 2);
            }

            @include breakpoint(medium) {
                max-width: calc(#{$container-width-desktop} + (100% - #{$container-width-desktop}) / 2);
            }
        }

        #{$self}_position_left & {
            @include breakpoint(small) {
                float: left;
            }
        }

        #{$self}_position_right & {
            @include breakpoint(small) {
                float: right;
            }
        }
    }

    &__caption {
        max-width: 480px;
        margin-top: 16px;
        @include font-size(16px, 24px);

        @include rtlAlignment {
            margin-left: auto;
        }
    }
}