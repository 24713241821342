.recipe-filter {
    $self: &;
    position: relative;

    &_4-columns {
        position: unset;
    }

    &__heading {
        @include capitalized-label;
        margin-bottom: 4px;

        @include breakpoint(medium) {
            margin-bottom: 8px;
        }
    }

    &__panel {
        display: none;
        position: absolute;
        margin-top: 20px;
        background-color: $primary-blue;
        padding: 28px 36px;
        z-index: 3;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-10px);
        transition: transform 150ms;

        @include breakpoint(medium) {
            display: block;
        }

        &_opened {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }

        #{$self}_2-columns & {
            columns: 2;
        }

        #{$self}_3-columns & {
            columns: 3;
        }

        #{$self}_4-columns & {
            columns: 4;
            padding: 44px $spacing-large;
            left: $spacing-xlarge;
            right: $spacing-xlarge;
        }
    }

    &__option {
        display: block;
        color: $white;
        cursor: pointer;
        @include font-size(20px, 28px);
        @include focus-outline(5px);
        width: 278px;
        max-width: 100%;
        background: none;
        padding: 0;
        text-align: left;
        border: none;
        font-family: $font-family;
        @include rtlAlignment {
            text-align: right;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }

        &_selected {
            font-weight: $font-weight-bold;
        }
    }

    &__desktop-selector {
        body & {
            display: none;

            @include breakpoint(medium) {
                display: inline-block;
            }
        }
    }

    &__mobile-selector {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        @include font-size(20px, 28px);
        font-family: $font-family;
        font-weight: $font-weight-bold;
        color: $primary-blue;
        background: none;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        text-decoration: underline;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMS41TDggOC41TDE1IDEuNSIgc3Ryb2tlPSIjMDAwMDRGIiBzdHJva2Utd2lkdGg9IjIuNSIvPgo8L3N2Zz4K");
        background-position: 100% 50%;
        background-repeat: no-repeat;

        &:active {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMS41TDggOC41TDE1IDEuNSIgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuNSIvPgo8L3N2Zz4K");            background-color: $primary-blue;
            color: $white;
            text-decoration: none;
        }

        body & {
            padding: 0 25px 0 0;
            box-shadow: none;
        }

        &-holder {
            position: relative;
            display: inline;

            @include breakpoint(medium) {
                display: none;
            }

            span {
                display: inline-block;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
            }
        }
    }
}