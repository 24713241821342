@import "../../shared-components-styles/teasers-block-mixins.scss";

.preparation-block {
    $self: &;

    &__container {
        @include container-with-double-padding;
        max-width: $max-width-l;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include breakpoint(small) {
            flex-direction: row;
            gap: $spacing-xmedium;
        }

        @include breakpoint(medium) {
            gap: 54px;
        }
    }

    &__heading {
        @include teasers-block-heading;

        @include breakpoint(medium) {
            margin: 0 0 48px;
            margin-left: 0;
        }
    }

    &__image {
        transition: transform 350ms;

        #{$self}__link:hover &,
        #{$self}__link:focus-visible &,
        #{$self}__link:active & {
            transform: scale(1.05);
        }

        &-holder {
            overflow: hidden;
            flex: 0 0 48.75%;
        }
    }

    &__content {
        flex: 1;
    }

    &__link {
        display: block;
        text-decoration: none;
        @include focus-outline;

        &-heading {
            @include font-size(36px, 42px);
            margin: 0 0 20px;

            @include breakpoint(medium) {
                @include font-size(50px, 52px);
                margin: 0 0 44px;
            }            

            &-text {
                @include underlined-link(3px);
    
                #{$self}__link:hover &,
                #{$self}__link:focus-visible &,
                #{$self}__link:active & {
                    @include underlined-link-with-bg;
                }
            }
        }
    }

    &__icon {
        width: 32px;
        height: 32px;
        margin-left: 0;
        margin-top: auto;
        transition: margin-left 250ms;
        position: relative;
        z-index: 2;
    
        @include breakpoint(small) {
            width: 34px;
            height: 34px;
        }

        #{$self}__link:hover &,
        #{$self}__link:focus-visible & {
            margin-left: 30px;

            @include breakpoint(medium) {
                margin-left: 46px;
            }
        }

        #{$self}__link:active & {
            margin-left: 44px;

            @include breakpoint(medium) {
                margin-left: 66px;
            }
        }
    }
}