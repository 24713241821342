.no-results {
    @include container;
    text-align: center;
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;

    &__heading {
        margin: 0 0 $spacing-medium;
    }

    &__text {
        max-width: 580px;

        body & {
            margin: $spacing-xmedium auto 0;
        }
    }
}