@mixin overview-page-results-container {
    position: relative;
    @include container;
    margin-top: 44px;
    margin-bottom: $block-margin-mobile;

    @include breakpoint(medium) {
        margin-top: $block-margin-desktop;
        margin-bottom: $block-margin-desktop;
    }
}

@mixin overview-page-pager {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-large;
    margin-right: 9px;
    margin-left: auto;

    @include breakpoint(medium) {
        margin-top: 63px;
        margin-right: 82px;
    }
}

@mixin overview-page-teaser-item {
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }

    @include breakpoint(small) {
        margin-bottom: 0;
        width: calc(((100%  - $spacing-xmedium) / 2));
    }

    @include breakpoint(medium) {
        width: calc(((100%  - $spacing-xmedium * 2) / 3));
    }

    @include breakpoint(large) {
        width: calc(((100%  - $spacing-xmedium * 3) / 4));
    }
}

@mixin overview-page-teaser-list {
    list-style: none;
    margin: 0 -#{$spacing-xmedium};

    @include breakpoint(small) {
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        gap: 44px $spacing-xmedium;
    }
}