@import "../../shared-components-styles/teasers-block-mixins.scss";

.story-teasers-block {
    $self: &;
    @include rtlAlignment;

    &__container {
        @include container;
        overflow: hidden;

        &_2teasers {
            max-width: 1312px;
        }
    }

    &__heading {
        @include teasers-block-heading;
        margin-bottom: 44px;

        @include breakpoint(medium) {
            margin-bottom: 64px;
        }
    }

    &__teasers-wrapper {
        #{$self}__container_2teasers & {
            @include breakpoint(small) {
                display: flex;
                gap: $spacing-xmedium;
            }
        }
    }

    &__show-more-link {
        @include teasers-block-more-link;
        margin-top: 37px;

        @include breakpoint(medium) {
            margin-top: 47px;
        }
    }
}