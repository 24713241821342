.icon-button {
    $self: &;
    display: block;
    position: relative;
    border: none;
    width: 54px;
    height: 54px;
    cursor: pointer;
    background: none;
    @include focus-outline;

    &:before {
        content: '';
        @include full-size;
        background-color: $primary-blue;
        border-radius: 50%;
        z-index: 0;
        transition: all 200ms;
    }

    &:hover {
        &:before {
            transform: scale(1.15);
        }
    }

    &:active, &_active {
        &:before {
            transform: scale(1.37);
        }
    }

    &__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 350ms;
        max-width: 26px;
        max-height: 26px;
        color: $white;
        will-change: transform;
    }

    &:disabled{
        pointer-events: none;
        opacity: 0.5;
    }
}