.embedded-video-block {
    $self: &;

    &__container {
		@include container;
        max-width: 1100px;
	}

    &__image {
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 1;
        visibility: visible;
        transition: opacity 350ms, visibility 350ms;

        &_hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__video {
        &-holder {
            @extend .image_size_embedded-video-block;
        }
    }

    &__figure {
        margin: 0;
    }

    &__play-btn {
        display: inline-block;
        @include full-size;
		border-radius: 50%;
		background-color: $primary-blue;
		border: none;
		cursor: pointer;
        z-index: 3;
        outline: none;
        transition: all 250ms;

        &:hover {
            top: -7px;
            left: -7px;
            right: -7px;
            bottom: -7px;
        }

        &:active {
            top: -14px;
            left: -14px;
            right: -14px;
            bottom: -14px;
        }

        &-holder {
            width: 80px;
		    height: 80px;
            @include center-align(absolute);
            z-index: 2;
        }

        &-outline {
            @include full-size;

            &:after {
                content: '';
                position: absolute;
                top: -10px;
                left: -10px;
                right: -10px;
                bottom: -10px;
                opacity: 0;
                pointer-events: none;
                border: 3px solid $primary-red;
                z-index: 3;
                transition: all 250ms;

                #{$self}__play-btn:not(:active):focus-visible + & {
                    opacity: 1;
                }
            }
        }

        &-icon {
            @include center-align(absolute);
            margin-left: 3px;
            transition: opacity 250ms, visibility 250ms;

            #{$self}__play-btn:hover &,
            #{$self}__play-btn:focus-visible & {
                opacity: 0;
                visibility: hidden;
            }

            &_filled {
                opacity: 0;
                visibility: hidden;

                #{$self}__play-btn:hover &,
                #{$self}__play-btn:focus-visible & {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__loading {
        &:before {
            background: none !important;
        }
    }

    &__caption {
        @extend .container_small;
        @include font-size(16px, 24px);
        margin: 10px 12px 0;
        width: auto;

        @include breakpoint(medium) {
            margin: 10px auto 0;
            width: 100%;
        }
    }

    iframe { // generated by React-player module https://www.npmjs.com/package/react-player
		@include full-size;
        object-fit: cover;
	}

    video { // generated by React-player module https://www.npmjs.com/package/react-player
        @include full-size;
        object-fit: cover;
    }
}