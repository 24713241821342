$filter-bottom-indent: 30px;

.recipe-filters {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -#{$filter-bottom-indent};
        @include rtlAlignment;
    }

    &__item {
        margin-bottom: $filter-bottom-indent;
        max-width: 100%;

        &:not(:last-child) {
            margin-right: 32px;

            @include rtlAlignment {
                margin-left: 32px;
                margin-right: 0;
            }

            @include breakpoint(medium) {
                margin-right: 7.5%;

                @include rtlAlignment {
                    margin-left: 7.5%;
                    margin-right: 0;
                }
            }
        }
    }

    &__heading {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }
}