.image-collage-block {
    $self: &;

    &__container {
        @include container;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: $spacing-xmedium;
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(small) {
            flex-direction: row;
            padding-left: $spacing-xmedium;
            padding-right: $spacing-xmedium;
        }

        @include breakpoint(medium){
            padding-left: $spacing-xlarge;
            padding-right: $spacing-xlarge;
        }

        &:after {
            content: none;
        }
    }

    &__image {        
        &:not(:last-child) {
            margin-bottom: $spacing-xmedium;
        }

        #{$self}__col_left & {
            @include breakpoint(small) {
                margin-left: auto;
            }

            &:first-child {
                width: 76%;

                @include breakpoint(small) {
                    width: 100%;
                }
            }

            &:last-child {
                &:not(:first-child) {
                    padding-right: $spacing-xmedium;
                    width: 70%;
                    margin-left: auto;
                }

                @include breakpoint(small) {
                    width: 90%;
                    padding-right: 0 !important;
                }
            }
        } 

        #{$self}__col_right & {
            &:first-child {
                &:not(:last-child) {
                    padding-left: $spacing-xmedium;
                    width: 76%;
                }
            
                @include breakpoint(small) {
                    width: 74%;
                    padding-left: 0 !important;
                }
            }

            &:last-child {
                width: 76%;
                margin-left: auto;

                @include breakpoint(small) {
                    width: auto;
                    margin-left: 0;
                }
            }
        }

        #{$self}__col_right_reversed & {
            &:first-child {
                margin-left: auto;
                padding-right: $spacing-xmedium;

                @include breakpoint(small) {
                    margin-left: 0;
                    padding-right: 0;
                }
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        max-width: 648px;
        width: 100%;

        @include breakpoint(small) {
            max-width: none;
            width: calc((100% - $spacing-xmedium) / 2);
        }
    }
}