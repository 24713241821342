.how-to-step-block {
    display: flex;
    flex-direction: column;
    @include container;

    @include breakpoint(small) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__image-container {
        width: 100%;
        margin-bottom: $spacing-xmedium;

        @include breakpoint(small) {
            flex: 0 0 49.2%;
            margin-bottom: 0;
        }
    }

    &__text-container {
        @include breakpoint(small) {
            flex: 0 0 50.8%;
            padding-left: $spacing-xmedium;
        }
    }

    &__number{
        @include font-size(18px, 26px);
        @include rtlAlignment;

        @include breakpoint(medium) {
            margin-bottom: 15px;
        }
    }

    &__heading{
        display: block;
        @include font-size(36px, 40px);
        margin: 0 0  32px;

        @include breakpoint(medium) {
            @include font-size(40px, 44px);  
        }
    }

    &__description{
        clear: both;
    }
}