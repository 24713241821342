$content-left-indent: 64px;

@mixin teasers-block-heading {
    @include font-size(26px, 32px);
    font-weight: 400;
    margin: 0 0 34px;

    @include breakpoint(medium) {
        @include font-size(32px, 38px);
        margin: 0 0 52px $content-left-indent;
    }
}

@mixin teasers-block-more-link {
    margin-top: 30px;

    @include breakpoint(medium) {
        margin-left: $content-left-indent;
    }
}