@import "../../shared-components-styles/image-mixins.scss";

.image {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &_fluid {
    @include fluid-image();
  }

  &__img {
    display: block;
    width: 100%;
  }

  // Example of the possible image sizes

  &_size {

    &_front-page-hero,
    &_specimen-page-hero {
      transition: padding-bottom var(--page-hero-expansion-animation-duration);
      @include fixed-proportion-image(648, 580);
    }

    &_front-page-hero_tight,
    &_specimen-page-hero_tight {
      @include fixed-proportion-image(648, 440);
    }

    &_recipe-page-hero {
      @include fixed-proportion-image(684, 600);
    }

    &_recipe-page-tight {
      @include fixed-proportion-image(684, 420);
    }

    &_short-article-page-hero {
      @include fixed-proportion-image(984, 544);
    }

    &_header-logo {
      @include logo-image(62, 62);

      @include breakpoint(small) {
        @include logo-image(79, 78);
      }
    }

    &_specimen-teaser-block-logo {
      @include logo-image(72, 69);

      @include breakpoint(small) {
        @include logo-image(92, 88);
      }
    }

    &_how-to-buy-block-logo {
      @include logo-image(66, 65);

      @include breakpoint(small) {
        @include logo-image(94, 92);
      }
    }

    &_footer-banner,
    &_long-article-page-hero {
      @include fluid-image();
    }

    &_footer-logo {
      @include logo-image(195, 44);

      @include breakpoint(medium) {
        @include logo-image(227, 44);
      }
    }

    &_recipe-teaser {
      @include fixed-proportion-image(312, 200);
    }

    &_story-teaser-large {
      @include fixed-proportion-image(391, 340);

      @include breakpoint(small) {
        @include fixed-proportion-image(984, 520);
      }
    }

    &_story-teaser-medium {
      @include fixed-proportion-image(342, 240);

      @include breakpoint(small) {
        @include fixed-proportion-image(504, 532);
      }
    }

    &_story-teaser-small {
      @include fixed-proportion-image(258, 228);
    }

    &_article-teaser-large {
      @include fixed-proportion-image(584, 333);
    }

    &_article-teaser-small {
      @include fixed-proportion-image(381, 278);
    }

    &_article-teaser-medium {
      @include fixed-proportion-image(583, 370);
    }

    &_species-quick-overview-block {
      @include fixed-proportion-image(363, 318);
    }

    &_specimen-search-teaser,
    &_search-teaser {
      @include fixed-proportion-image(289, 250);
    }

    &_specimen-teaser-block {
      @include fixed-proportion-image(648, 411);
    }

    &_text-with-background-block,
    &_quote-with-background-block {
      @include fluid-image();
    }

    &_how-to-step-block{
      @include fixed-proportion-image(648, 420);
    }

    &_image-block {
      &-left,
      &-right {
        @include original-proportions-image(958, 660);

        @include breakpoint(medium) {
          @include original-proportions-image(925, 633);
        }
      }
      &-center {
        @include original-proportions-image(722, 796);

        @include breakpoint(medium) {
          @include original-proportions-image(650, 714);
        }
      }
    }

    &__image-with-text-and-bg-color-block{
      @include original-proportions-image(467, 540);
    }

    &_preparation-block {
      @include fixed-proportion-image(584, 370);
    }

    &_collage-left-top {
      @include original-proportions-image(648, 720);
    }

    &_collage-left-bottom {
      @include original-proportions-image(584, 360);
    }

    &_collage-right-top {
      @include original-proportions-image(480, 480);
    }

    &_collage-right-bottom {
      @include original-proportions-image(648, 340);
    }

    &_how-to-buy-block {
      @include fixed-proportion-image(560, 308);
    }

    &_embedded-video-block {
      @include fixed-proportion-image(980, 550);
    }
  }
}