$header-height: 158px;
$header-height-mobile: 110px;

.header {
    $self: &;
    position: fixed;
    top: -$header-height-mobile;
    right: 0;
    z-index: 1;
    padding: 22px $spacing-xmedium 22px 32px;
    min-height: $header-height-mobile;
    width: convert-to-rem(280px);
    max-width: 100%;
    background-color: $primary-blue;
    overflow: hidden;
    transition: top var(--header-animation-duration);
    top: -$header-height-mobile;

    @include breakpoint(medium) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: convert-to-rem(440px);
        align-items: center;
        padding-left: $spacing-large;
        padding: $spacing-large $spacing-xmedium $spacing-large $spacing-large;
        top: -$header-height;
        min-height: $header-height !important;
        transition: top var(--header-animation-duration), width 200ms;
    }

    @include breakpoint(large) {
        right: $spacing-xlarge;
    }
    
    @include breakpoint(container-max, min) {
        right: calc((100% - #{$max-width}) / 2 + $spacing-xlarge);
    }

    &_animated {
        top: 0 !important;
    }

    &_search-expanded {
        @include breakpoint(medium) {
            width: convert-to-rem(706px);
        }
    }

    &_menu-opened {
        width: 100%;
        bottom: 0;
        overflow: auto;

        @include breakpoint(medium) {
            width: convert-to-rem(674px);
            bottom: auto;
        }
    }

    &__top-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__menu {
        display: none;
        flex: 0 0 100%;
        padding-top: 160px;

        @include breakpoint(medium) {
            padding: $spacing-xlarge 0;
            transition: width 200ms;
        }

        &_opened {
            display: block;
        }

        &-wrapper {
            @include breakpoint(medium) {
                max-width: 306px;
            }
        }

        &-container {
            @include breakpoint(medium) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &__secondary-menu {
        margin-top: $spacing-extralarge;

        @include breakpoint(medium) {
            margin-top: 64px;
        }
    }

    &__logo {
        display: inline-block;
        flex: 0 0 62px;

        @include breakpoint(medium) {
            flex: 0 0 79px;
        }
    }

    &__quick-search {
        position: absolute;
        top: 134px;
        left: $spacing-xmedium;
        right: $spacing-xmedium;

        @include breakpoint(medium) {
            position: static;
            display: inline-flex;
            justify-content: center;
            flex: 1;
            padding: 0 $spacing-xmedium;

            #{$self}_search-expanded & {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}