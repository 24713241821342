@import "../../shared-components-styles/text-with-bg-block-mixins.scss";

.quote-with-background-block {
    $self: &;
    @include text-with-background-block;


    &__container {
        @include text-with-background-block-container;
    }

    &__inner {
        @include container-with-double-padding;
    }

    &__quote {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include rtlAlignment;
    }

    &__text {
        @include text-with-background-block-text;
        margin: 0;
        float: none;

        &-container {
            &:before {
                content: '«';
            }

            &:after {
                content: '»';
            }
        }
    }

    &__text-animation-container {
        @include text-with-background-block-text-animation-container;
    }

    &__caption {
        display: inline-flex;
        align-self: flex-start;
        flex-direction: column;
        color: $white;
        @include font-size(18px, 26px);
        padding: $spacing-xmedium 16px $spacing-medium;
    }

    &__author {
        font-weight: $font-weight-bold;
    }

    &__title {
        @include font-size(20px, 26px);
        font-weight: $font-weight-light;

        #{$self}__author + & {
            margin-top: 1px;
        }
    }

    &__text-container {
        @include text-with-background-block-text-container;
    }
}