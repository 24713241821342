.origin-matters {
    $self: &;
    position: relative;
    padding-top: 10px;
    
    &__text{
        @include font-size(18px, 22px);
        font-weight: $font-weight-bold;
    }

    &__line {
        display: block;
        position: absolute;
        height: 6px;
        top: 0;
        background-color: $primary-red;
    }

    &_line-position_left #{$self}__line {
        right: 0;
        left: -1999px;
        transition: left 300ms;
    }

    &_line-position_right #{$self}__line {
        right: -1999px;
        left: 0;
        transition: right 300ms;
    }
}
