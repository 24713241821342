.icon-label {
   $self: &;
   display: flex;
   flex-direction: row;
   @include rtlAlignment;
   
   &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      align-self: center;

      @include rtlAlignment {
         margin-left: 8px;
         margin-right: 0;
      };
   }

   &__text {
      align-self: center;
      display: block;
      @include font-size(18px, 21px);

      &_hidden {
         @include visually-hidden;
      }

      @include breakpoint(small) {
         @include font-size(20px, 28px);
      }

      #{$self}_small & {
         @include font-size(16px, 24px);
      }
   }
}