@import "./image-mixins.scss";

@mixin text-with-background-block {
    max-width: $max-bg-width;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    &:after {
        display: block;
        content: '';
        width: 100%;

        @include breakpoint(small, max) {
            height: 100vh;
        }

        @include breakpoint(small) {
            height: auto;
            @include fixed-proportion(1440, 900);
        }
    }
}

@mixin text-with-background-block-container {
    z-index: 2;
    padding: 0 0 32px;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;

    @include breakpoint(medium) {
        padding: 0 0 44px;
    }
}

@mixin text-with-background-block-text {
    color: $maincolor;
    max-width: calc(100vw - 48px);
    @include font-size(36px, 40px);
    float: left;
    font-weight: 700;
    @include rtlAlignment;

    @include breakpoint(small) {
        max-width: 490px;
    }

    @include breakpoint(medium) {
        @include font-size(40px, 44px);
    }
}

@mixin text-with-background-block-text-container {
    display: inline;
}


@mixin text-with-background-block-text-animation-container {
    width: 100%;
    height: 500px;
    overflow-y: visible !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include rtlAlignment;
}