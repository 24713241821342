@import "../shared-components-styles/content-item-mixins.scss";

.content-area {
    $self: &;
    &__item {
        @include content-item;

        &_no-gap{
            margin-bottom: 0;

            & + #{$self}__item:not(#{$self}__item_no-gap){
               margin-top: $block-margin-mobile;

               @include breakpoint(medium) {
                margin-top: $block-margin-desktop;
               }
            }

            &:last-child{
                margin-bottom: 64px;
            }
        }
    }
}
