@import "../../shared-components-styles/overview-page-mixins.scss";

.search-page {
    $self: &;
    
    &__search-form {
        &-holder {
            @include breakpoint(medium) {
                width: 47%;
                min-height: 114px;
            }
        }
    }

    &__hits-text {
        #{$self} & {
            @include font-size(20px, 28px);
            margin: 22px 0;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }
    }

    &__settings-panel {
        gap: 9%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__results-container {
        @include overview-page-results-container;
    }

    &__teasers {
        @include overview-page-teaser-list;
    }

    &__teaser-item {
        @include overview-page-teaser-item;
    }

    &__teaser {
        height: 100%;
    }

    &__pager {
        @include overview-page-pager;
    }

    &__filter {
        margin: 13px 0 27px;
        flex: 1;

        @include breakpoint(medium) {
            display: flex;
            justify-content: flex-end;
            margin: 11px 9px 0 0;
        }
    }
}