.share-and-rate-recipe-panel {
    background: $light-red;
    padding: 48px $spacing-xmedium 0;
    margin: 64px -#{$spacing-xmedium} 0;
    @include cf;
    @include rtlAlignment;
    
    @include breakpoint(medium) {
        padding: $spacing-large $spacing-large 0;
        margin: 64px 0 0;
    }
}