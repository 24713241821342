.quick-search {
    &__btn {
        display: none;
        align-items: center;
        border: none;
        background: none;
        color: $white;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        @include font-size(18px, 26px);
        cursor: pointer;
        @include focus-outline;

        @include breakpoint(medium) {
            display: inline-flex;
        }

        &_hidden {
            display: none;
        }
    }

    &__icon {
        margin-left: 8px;
    }

    &__holder {
        display: none;
        width: 100%;

        &_expanded {
            display: block;
        }
    }
}