//------------------------------------
//  BASE
//------------------------------------
$website-bg-color: #fff !default;


// Enable font smoothing
$enable-font-smoothing: true !default;


// Base font sizes and colors

$tera-size: 53px !default;
$giga-size: 28px !default;
$extra-large-size: 20px !default;
$large-size: 18px !default;
$medium-size: 16px !default;
$small-size: 14px !default;
$extra-small-size: 12px !default;



//------------------------------------
//  COLORS
//------------------------------------



$white: white;
$black: black;

// Primary colors
$primary-blue: #00004F;
$primary-red: #FF003A;
$maincolor: $primary-blue;

// Secondary colors
$dark-green: #0C5761;
$light-green: #A8E7DD;
$light-blue: #BEEEFC;
$light-red: #EEE4DD;
$soft-blue: #BDD5DE;
$orange: #FFAE9C;

//-----------------------------------------------------
// Calculation variables
//-----------------------------------------------------
$base-font-size: 16px !default; // WARN! Never change - it is a constans needed for calculations.
$base-line-height: $extra-large-size !default;
$line-height-ratio: calc($base-line-height / $base-font-size);
//-------------------------------------------------------

$font-family: 'ESPeak', Arial, sans-serif;
$font-size-em : #{calc($extra-large-size / $base-font-size) * 1em};
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$print-font-family: Georgia, "Times New Roman", Times, serif !default;

$line-height: $giga-size;

$font-color: $maincolor;
$link-color: $primary-blue;
$link-hover-color: $primary-blue;

$validation-color: red;


//------------------------------------
// Spacing
//------------------------------------

$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-xmedium: 24px;
$spacing-large: 40px;
$spacing-xlarge: 60px;
$spacing-extralarge: 80px;
$spacing-xxlarge: 96px;
$spacing-giga: 120px;
$spacing-narrow: 220px;

$max-width-s: 770px;
$max-width-s-with-paddings: 828px;
$max-width: 1440px;
$max-bg-width: 1800px;
$max-width-l: 1560px;
$max-text-width: 600px;

$min-width: 320px;

$block-margin-mobile: 72px;
$block-margin-desktop: $spacing-extralarge;

//------------------------------------
//  RESPONSIVE
//------------------------------------

$ipad-pro-breakpoint: 1025px;
$full-hd-breakpoint: 1920px;

// Breakpoints
$breakpoint-map: (
  "extra-small": 30em, //480px
  "extra-small-max": 47.999em,
  "small":       48em, //ipad-min
  "small-max": 63.999em,
  "medium":      64em, //ipad-max
  "medium-max": 79.999em,
  "large":       80em, //1280px
  "extra-large": 90em,//1440px
  "full-hd": $full-hd-breakpoint,
  "container-max": $max-width, //1440px centered container
  "ipad": (min-device-width 768px) (max-device-width 1024px),
  "ipad-pro": $ipad-pro-breakpoint,
  "iphone": (min-device-width 320px) (max-device-width 480px),
  "iphone5": (min-device-width 320px) (max-device-width 568px),
);

// Selection colors
$selection-background:  $primary-blue;
$selection-color:       $maincolor !default;


// Form placeholder colors
// $placeholder-color:     #bbbbbb !default;


// Colors used for our objects' borders, etc
// $object-ui-color:       #329C42 !default;


// Button
// $button-font-color: $maincolor !default;
// $button-hover-font-color: $white;


//------------------------------------
//  Components
//------------------------------------

// Gutter size
$gutters: 2% !default;


// Prefix for grid columns. By default columns
// are formatted as `.column-8`
$column-prefix: 'column-' !default;


// Dictate how many columns you would like your
// grid system to use. Default is 12.
$column-number: 16 !default;

$use-grid:           true !default;
$use-push:	       true !default;
$use-pull:	       true !default;


$page-hero-content-expansion-multiplier: 0.3;

//------------------------------------
//  Animation variables
//------------------------------------

:root {
  --page-content-fade-animation-timeout: 800ms;
  --page-content-fade-animation-duration: 250ms;

  --header-animation-timeout: 1050ms;
  --header-animation-duration: 400ms;

  --page-hero-bg-fade-animation-duration: 800ms;
  --page-hero-content-fade-animation-duration: 250ms;
  --page-hero-expansion-animation-duration: 400ms;

  --page-hero-bg-appearance-duration: 800ms;
  --page-hero-bg-expansion-duration: 250ms;

  --page-hero-expanding-layer-max-height: 1173px;
}
