@import "../../shared-components-styles/recipe-page.scss";

.recipe-page {
  &__columns {
    display: flex;
    flex-direction: column;
    @extend .container;
    margin-bottom: $spacing-xlarge;

    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }

  &__column {
    padding-top: $columns-top-padding;

    &:first-child {
      @include breakpoint(medium) {
        width: 400px;
        padding-right: $spacing-xlarge;
      }

      @include breakpoint(large) {
        padding-left: $spacing-xlarge;
        width: 500px;
      }
    }

    &:last-child {
      @include breakpoint(medium) {
        flex: 1;
        max-width: 648px;
      }

      @include breakpoint(medium, $min-max: max) {
        padding-top: 16px;
      }
    }
  }

  &__instructions-heading {
    @extend h3;
    @include recipe-page-heading;
  }

  &__body {
    @include breakpoint(medium) {
      padding-left: 32px;
      max-width: 570px;

      @include rtlAlignment {
        margin-left: auto;
      }
    }
  }
}