.rate-recipe {
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    padding-bottom: 78px;
    $self: &;
    z-index: 1;

    @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__heading {
        display: block;
        margin: 0 0 16px;
        @include font-size(18px, 26px);
        font-weight: 700;

        @include rtlAlignment {
            margin: 0 0 0 16px;
        }

        @include breakpoint(medium) {
            align-self: center;
            margin: 0 27px 0 0;
            @include font-size(24px, 28px);

            @include rtlAlignment {
                margin: 0 0 0 27px;
            }
        }
    }

    &__items {
        display: flex;
        flex-direction: row;
        list-style: none;
    }

    &__item-container {
        margin: 0 16px 0 0;

        @include rtlAlignment {
            margin: 0 0 0 16px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__item {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        width: 48px;
        height: 48px;
        position: relative;
        cursor: pointer;
        @include focus-outline;

        &:after {
            content: "";
            display: block;
            width: 48px;
            height: 48px;
            background: $maincolor;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transition: transform 0.25s ease-in-out;
            transform: scale(1);
        }

        #{$self}__item_active:after,
        &:hover:after,
        &:focus-visible:after {
            transform: scale(1.4);
        }

        &:active:after {
            transform: scale(1.5);
        }

        &:disabled {
            pointer-events: none;
        }

        &-icon {
            z-index: 2;
            display: block;
            @include center-align(absolute);
            will-change: transform;
            transition: opacity 0.25s ease-in-out;
            opacity: 1;

            &_empty {
                #{$self}__item_active &,
                #{$self}__item:hover &,
                #{$self}__item:focus-visible & {
                    opacity: 0;
                }
            }

            &_full {
                opacity: 0;
                z-index: 3;

                #{$self}__item_active &,
                #{$self}__item:hover &,
                #{$self}__item:focus-visible & {
                    opacity: 1;
                }
            }
        }
    }

    &__notifications {
        width: 100%;
        flex: 0 0 1;
        margin-top: 10px;
        min-height: 28px;
        position: absolute;
        top: 100%;
        left: 0;
        @include font-size(20px, 28px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__hint {
        opacity: 0;
        animation: fadeIn 0.25s ease-in-out forwards;
    }

    &__error-text {
        color: $primary-red;
    }
}