@import "../../shared-components-styles/text-with-bg-block-mixins.scss";

.parallax-bg-image {

    &__scroll-container {
        @include full-size;
    }

    &__image-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: calc(100% * 1.40);
    }
}