@import "../../shared-components-styles/search-teaser-mixins.scss";

.search-teaser {
    $self: &;
    @include search-teaser;

    &__background {
        @include full-size;
        transition: all 350ms;

        #{$self}:active & {
            left: -15px;
            bottom: -15px;
        }
    }

    &__container {
        @include search-teaser-container;
    }

    &__image {
        transition: transform #{$animation-speed} ease-in-out;

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            transform: scale(1.25);
        }
    }

    &__image-aligner {
        @include search-teaser-image-aligner;
    }

    &__image-holder {
        position: relative;
        overflow: hidden;
        height: 100%;

        &_no-image {
            @extend .image_size_search-teaser;
        }
    }

    &__category {
        @include search-teaser-category;
    }

    &__content {
        @include search-teaser-content;
    }

    &__heading {
        @include font-size(22px, 26px);
        color: inherit;
        margin: 0;
        font-weight: $font-weight-bold;

        @include breakpoint(small) {
            @include font-size(24px, 28px);
        }

        &-text {
            #{$self}:hover &,
            #{$self}:focus-visible &,
            #{$self}:active & {
                text-decoration: underline;
            }        
        }
    }
}