$desktop-image-width-large: 363px;
$desktop-image-width: 280px;

.species-quick-overview-block {
    background-color: $light-blue;
    transition: background-color 0.35s;

    &__container {
        @include container;
        display: flex;
        flex-direction: column;
        $self: &;
        padding-top: 64px;
        padding-bottom: $spacing-extralarge;
        
        @include breakpoint(medium) {
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: $spacing-extralarge;
            padding-bottom: calc(#{$spacing-extralarge} + 16px);
        }
    }

    &__heading {
        margin: 0 0 37px;

        @include breakpoint(medium) {
            margin-bottom: $spacing-xmedium;
        }
    }

    &__intro {
        margin-bottom: 25px;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }

    &__text-content {
        @include breakpoint(medium) {
            flex: 0 0 35%;
            padding-right: $spacing-large;
        }

        @include breakpoint(large) {
            padding: 16px $spacing-xlarge 0;
            flex: 0 0 43%;
        }
    }

    &__links-container {
        @include breakpoint(medium) {
            flex: 0 0 65%;
        }

        @include breakpoint(large) {
            padding-top: 10px;
            flex: 0 0 57%;
        }
    }

    &__species {
        position: relative;

        &-list {
            list-style: none;

            @include breakpoint(medium) {
                min-height: 320px;
            }

            &-item {
                @include breakpoint(small-max, max) {
                    padding-top: 0;
                }

                @include breakpoint(medium) {
                    margin-bottom: 17px;
                    padding-right: calc(#{$desktop-image-width} - 20px);

                    @include rtlAlignment {
                        padding-right: calc(#{$desktop-image-width} + 20px);
                    }
                }

                @include breakpoint(large) {
                    padding-right: calc(#{$desktop-image-width-large} - 20px);

                    @include rtlAlignment {
                        padding-right: calc(#{$desktop-image-width-large} + 20px);
                    }
                }
            }
        }
    }

    &__specie {
        @include breakpoint(small-max, max) {
            padding-top: 13px;
            padding-bottom: 13px;
        }

        &-image {
            &-holder {
                width: 100%;

                @include breakpoint(small-max, max) {
                    height: 0;
                    transition: height 0.25s ease-in-out;
                    overflow: hidden;

                    &_mobile-active {
                        height: 272px;
                    }
                }
            }

            &-wrapper {
                @include breakpoint(small-max, max) {
                    width: 100%;
                    max-width: 260px;
                    margin-bottom: 24px;

                    &_mobile-align_left {
                        float: left;
                    }

                    &_mobile-align_left-with-padding {
                        float: left;
                        margin-left: 10%;
                    }

                    &_mobile-align_right {
                        float: right;
                    }

                    &_mobile-align_right-with-padding {
                        float: right;
                        margin-right: 10%;
                    }

                    &_mobile-align_center {
                        margin-left: auto;
                        position: relative;
                        left: 10%;
                        margin-right: auto;
                    }
                }

                @include breakpoint(medium) {
                    display: block;
                    max-height: none;
                    position: absolute;
                    right: -20px;
                    top: -10px;
                    width: $desktop-image-width;
                    opacity: 0;

                    &_desktop-active {
                        opacity: 1;
                    }
                }

                @include breakpoint(large) {
                    width: $desktop-image-width-large;
                }
            }
        }
    }

    &__remaining-links-list {
        margin-top: 0px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        max-width: 500px;

        &-item {
            margin: 30px 24px 0 0;
        }
    }

    &__remaining-link {
        @include underlined-link;

        &:hover,
        &:focus-visible,
        &:active {
            @include underlined-link-with-bg;
        }
    }

    &__explore-all-link {
        margin-top: 55px;

        @include breakpoint(medium) {
            margin-top: 40px;
        }
    }
}