$pager-link-size: 50px;

@mixin pager-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $pager-link-size;
    height: $pager-link-size;
    cursor: pointer;
}

.pager {
    $self: &;
    display: inline-flex;
    position: relative;

    &__list {
        display: inline-flex;
        align-items: center;
        list-style: none;
    }

    &__break {
        display: none;
        margin-right: 12px;

        a {
            @include pager-link;
            text-decoration: none;
            width: 30px;
            @include focus-outline(0);
        }

        @include breakpoint(medium) {
            display: block;
        }
    }
        
    &__item {
        font-size: 22px;
        display: none;

        &:not(:last-child) {
            margin-right: 10px;
        }

        @include breakpoint(medium) {
            display: block;
        }
    }

    &__link {
        position: relative;
        @include pager-link;
        text-decoration: underline;
        @include focus-outline(0);
        transition: all 200ms;
        z-index: 0;

        &:before {
            content: '';
            @include full-size;
            border-radius: 50%;
            border: 2px solid transparent;
            z-index: 0;
            transition: all 200ms;
        }

        &:after {
            content: '';
            @include full-size;
            border-radius: 50%;
            background-color: $primary-blue;
            transform: scale(0);
            z-index: -1;
            transition: all 250ms;

            #{$self}__item.selected & {
                content: none;
            }
        }

        #{$self}__item.selected & {
            font-weight: $font-weight-bold;
            color: $primary-blue;
            text-decoration: none;

            &:before {
                border-color: $primary-blue;
            }
        }

        &:hover {
            color: $white;
            text-decoration: underline;

            &:after {
                transform: scale(0.75);
            }
        }

        &:active {
            color: $white;
            
            &:after {
                transform: scale(1.15);
            }
        }
    }

    &__prev,
    &__next {
        font-size: 20px;

        @include breakpoint(medium) {
            font-size: 22px;
        }

        a {
            position: relative;
            @include pager-link;
            font-weight: $font-weight-bold;
            text-decoration: underline;
            @include focus-outline(4px);

            &:before {
                content: '';
                width: 23px;
                height: 20px;
                @include center-align(absolute);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &.disabled {
            filter: grayscale(1);
            opacity: 0.2;
        }
    }

    &__prev {
        margin-right: 10px;

        @include rtlAlignment {
            margin-left: 10px;
            margin-right: 0;
        }

        a {
            text-indent: -9999px;
            font-size: 0;
    
            &:before {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMxLjk3IDI3Ljk3Ij4NCiAgICA8cmVjdCB4PSIyLjk3IiB5PSIxMi42MSIgd2lkdGg9IjI5IiBoZWlnaHQ9IjMuNiIgZmlsbD0iIzAwMDA0RiIvPg0KICAgIDxyZWN0IHg9Ii0xLjYzIiB5PSI2LjQ3IiB3aWR0aD0iMTkuOCIgaGVpZ2h0PSIzLjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjQzIDguMjcpIHJvdGF0ZSgtNDUpIiAgZmlsbD0iIzAwMDA0RiIgLz4NCiAgICA8cmVjdCB4PSI3LjA5IiB5PSIxMS4yNSIgd2lkdGg9IjMuNiIgaGVpZ2h0PSIxOC4xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuNzUgMTIuMjMpIHJvdGF0ZSgtNDUpIiBmaWxsPSIjMDAwMDRGIiAvPg0KPC9zdmc+");  
            }
        }
    } 

    &__next {
        margin-left: 20px;

        @include rtlAlignment {
            margin-left: 0;
            margin-right: 20px;
        }

        a {
            width: auto;
            flex-direction: row-reverse;
    
            &:before {
                display: inline-block;
                margin-left: 10px;
                position: static;
                transform: none;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMzIDI4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMyAyODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KICAgIDxyZWN0IHg9IjAiIHk9IjExLjgiIGZpbGw9IiMwMDAwNEYiIHdpZHRoPSIyOSIgaGVpZ2h0PSIzLjYiLz4NCiAgICA8cmVjdCB4PSIxMy44IiB5PSIxNy45IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgLTAuNzA3MSAwLjcwNzEgMC43MDcxIC02Ljk5MTMgMjIuNTYzMykiIGZpbGw9IiMwMDAwNEYiIHdpZHRoPSIxOS44IiBoZWlnaHQ9IjMuNiIvPg0KICAgIDxyZWN0IHg9IjIxLjMiIHk9Ii0xLjQiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzA3MSAtMC43MDcxIDAuNzA3MSAwLjcwNzEgMS4zNCAxOC42MzE4KSIgZmlsbD0iIzAwMDA0RiIgd2lkdGg9IjMuNiIgaGVpZ2h0PSIxOC4xIi8+DQo8L3N2Zz4NCg==");
                
                @include rtlAlignment {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }
    } 

    &__mobile-selector {
        padding: 12px;
        border: 2px solid $primary-blue;
        border-radius: 40px;
        min-width: 108px;
        font-family: $font-family;
        font-size: 22px;
        color: $primary-blue;
        background: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        text-decoration: underline;
        text-align: center;

        @include breakpoint(medium) {
            display: none;
        }

        &:active {
            background-color: $primary-blue;
            color: $white;
        }
    }
}