a {
	color: $primary-blue;
	text-decoration: underline;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

p {
	margin: 0 0 convert-to-rem(26px);
	font-size: inherit;
	line-height: inherit;
	@include rtlAlignment;

	@include breakpoint(medium) {
		margin-bottom: convert-to-rem(36px);
	}

	&:last-child {
		margin: 0;
	}
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-weight: 700;
	color: $primary-blue;
	margin: 48px 0 27px;

	@include breakpoint(medium) {
		margin: 48px 0 32px;
	}

	@include rtlAlignment;
}

h1, .h1 {
	@include font-size(46px, 52px);
	@include breakpoint(medium) {
		@include font-size(90px, 94px);
	}
}

h2, .h2 {
	@include font-size(36px, 40px);

	@include breakpoint(medium) {
		@include font-size(40px, 44px);
		margin-bottom: 36px;
	}
}

h3, .h3 {
	font-weight: 400;
	@include font-size(26px, 32px);

	@include breakpoint(medium) {
		@include font-size(32px, 38px);
	}
}

h4, .h4 {
	@include font-size(22px, 26px);

	@include breakpoint(medium) {
		@include font-size(24px, 28px);
		margin-bottom: 27px;
	}
}

h5, .h5 {
	@include font-size(18px, 22px);
	@include breakpoint(medium) {
		@include font-size(18px, 26px);
	}
}

img {
	max-width: 100%;
	display: block;
}

strong,
b {
	font-weight: bold;
	@include rtlAlignment;
}

a, figcaption, span {
	@include rtlAlignment;
}

em,
i {
	font-style: italic;
}

ul,
ol {
	padding: 0;
	margin: 0;
	@include rtlAlignment;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	margin: $spacing-large 0;
	@include rtlAlignment;
}

td,
th {
	vertical-align: top;
	border: 1px solid $black;
	padding: $spacing-medium;
}

mark {
	background-color: $selection-background;
}
