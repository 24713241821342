.skip-to-main {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	background: $black;
	display: flex;
	padding: 20px;
	color: $white;
	font-weight: bold;
	transition: transform 100ms ease-in-out, opacity 100ms linear;
	transform: translateY(-100%);
	outline: none;

	&:focus {
		transform: none;
	}

	@media print {
		display: none;
	}
}