@import "../../shared-components-styles/overview-page-mixins.scss";

.stories-overview-list {
    $self: &;

    &__results-container {
        @include overview-page-results-container;

        @include breakpoint(medium) {
            padding-left: 60px;
            padding-right: 60px;
        }

        @include breakpoint(large) {
            padding-left: 124px;
            padding-right: 124px;
        }
    }

    &__row {
        @include breakpoint(small) {
            display: flex;
            align-items: center;
            gap: $spacing-large;
        }
        
        @include breakpoint(large) {
            gap: 68px;
        }

        &:not(:last-child){
            margin-bottom: 44px;

            @include breakpoint(large) {
                margin-bottom: 64px;
            }
        }
    }

    &__col {
        &:not(:last-child){
            margin-bottom: 44px;
        }

        @include breakpoint(small) {
            display: flex;
            gap: $spacing-xmedium;
            width: 100%;
            margin-bottom: 0 !important;
        }

        #{$self}__row:not(:first-child):not(:last-child):nth-of-type(even) & {
            &:first-child {
                flex: 0 0 49%;
            }
            &:last-child {
                flex: 1;
            }
        } 

        #{$self}__row:not(:first-child):not(:last-child):nth-of-type(odd) & {
            &:first-child {
                flex: 1;
            }
            &:last-child {
                flex: 0 0 49%;
            }
        }
        
        &_mobile-stretched {
            margin-right: -$spacing-xmedium;

            @include breakpoint(small) {
                margin-right: 0;
            }
        }
    }

    &__teaser {
        height: 100%;
    }

    &__teaser-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;

        &:not(:last-child){
            margin-bottom: 16px;

            @include breakpoint(small) {
                margin-bottom: 0;
            }
        }
    }

    &__pager {
        @include overview-page-pager;
        margin-top: 66px;

        @include breakpoint(medium) {
            margin-top: 64px;
            margin-right: 20px;
        }
    }
}