@import "../../shared-components-styles/text-with-bg-block-mixins.scss";

.text-with-background-block {
    @include text-with-background-block;

    &__container {
        @include text-with-background-block-container;
    }

    &__inner {
        @include container-with-double-padding;
    }

    &__text {
        @include text-with-background-block-text;
    }

    &__text-container {
        @include text-with-background-block-text-container;
    }

    &__text-animation-container {
        @include text-with-background-block-text-animation-container;
    }
}