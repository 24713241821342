@import "../../shared-components-styles/overview-page-mixins.scss";

.recipe-list {
    $self: &;

    &__search-form {
        &-holder {
            @include breakpoint(medium) {
                width: 33%;
                min-height: 174px;
            }
        }
    }

    &__hits-text {
        #{$self} & {
            @include font-size(20px, 28px);
            margin: 22px 0;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }
    }

    &__results-container {
        @include overview-page-results-container;
    }

    &__teasers {
        @include overview-page-teaser-list;
    }

    &__teaser-item {
        @include overview-page-teaser-item;
    }

    &__teaser {
        height: 100%;
    }

    &__settings-panel {
        gap: 7.5%;

        @include breakpoint(medium) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__filters {
        flex: 1;

        &-toggle-btn {
            @include breakpoint(medium) {
                display: none !important;
            }
        }

        &-panel {
            display: none;
            margin-top: 44px;

            &_visible {
                display: block;
                margin-bottom: 18px;
            }

            @include breakpoint(medium) {
                margin: 0;
                display: block !important;
            }
        }
    }

    &__pager {
        @include overview-page-pager;
    }
}