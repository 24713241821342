@mixin dark-button-shape {
    background-color: $primary-blue;
    border-radius: $spacing-large;
}

@mixin light-button-shape {
    background-color: $white;
    border-radius: $spacing-large;
}

.button {
    $self: &;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    border: none;
    cursor: pointer;
    @include dark-button-shape;
    outline: none;
    transition: all 200ms;
    @include focus-outline;

    @include breakpoint(medium) {
        padding: 16px 20px;
    }

    &:before {
        content: '';
        @include full-size;
        @include dark-button-shape;
        z-index: 0;
        transition: all 200ms;
    }

    &_theme-light {
        @include light-button-shape;
        &:before {
            @include light-button-shape;
        }
    }

    &__text {
        color: $white;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        @include font-size(18px, 24px);
        z-index: 1;

        &_theme-light {
            color: $primary-blue;
        }
    }

    &:hover {
        outline-offset: 8px;
        &:before {
            transform: scale(1.1);
        }
    }

    &:active {
        outline-offset: 16px;
        &:before {
            transform: scale(1.3);
        } 
    }

    &:focus-visible {
        &:hover {
            &:after {
                transform: scale(1.1);
            }
        }
    
        &:active {
            &:after {
                transform: scale(1.3);
            }
        }
    }

    &__icon {
        position: relative;
        margin-left: 12px;
        color: $white;

        #{$self}_theme-light & {
            color: $primary-blue;
        }
    }
}