@import "../../shared-components-styles/rich-text-common.scss";
.poor-text {
    @include font-size(20px, 28px);

    a {
        @include underlined-link;
        font-weight: $font-weight-regular;

        &:hover,
        &:focus-visible {
            @include underlined-link-with-bg;
        }
    }

    &_in-front-page-hero,
    &_in-specimen-page-hero,
    &_in-short-article-page-hero,
    &_in-long-article-page-hero{
        @include font-size(24px, 32px);

        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &_in-recipe-page-hero,
    &_in-simple-page-hero,
    &_in_how-to-page-hero {
        @include font-size(24px, 32px);
        p{
            margin-bottom: 0;
        }
    }

    &_in-footer-banner{
        font-weight: 400;
        p{
            margin-bottom: 0;
        }
    }

    &_in-article-teasers-block{
        @include font-size(22px, 25px);

        @include breakpoint(medium) {
            @include font-size(24px, 32px);
        }
    }

    &_in-species-quick-overview-block{
        @include font-size(24px, 32px);

        @include breakpoint(medium) {
            @include font-size(20px, 28px);
        }
    }
}
