@import "../../shared-components-styles/footer-mixins.scss";

$text-bg: $primary-blue;

.footer-banner {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-bottom: $spacing-xxlarge;
    
    @include breakpoint(medium) {
        min-height: 565px;
    }

    @include breakpoint(large) {
        margin-left: $spacing-xlarge;
        margin-right: $spacing-xlarge;
        width: calc(100% - #{$spacing-xlarge}*2);
    }

    @include breakpoint(extra-large) {
        width: 100%;
        margin: 0;
    }

    @include breakpoint(full-hd) {
        
        max-width: $max-bg-width;
        margin: 0 auto;
    }

    &__heading {
        color: white;
        margin: 0 0 20px;

        @include breakpoint(medium) {
            margin-bottom: $spacing-large;
        }
    }

    &__background {
        @include footer-background;
    }

    &__color-container{
        position: relative;
        z-index: 2;

        &:before{
            content: "";
            @include full-size;
            display: block;
            background: $text-bg;
            right: 50%;

            @include breakpoint(extra-large) {
                left:  $spacing-xlarge;
             }
     
             @include breakpoint(full-hd) {
                 left: 0;
             }
        }
    }

    &__container {
        @include footer-container;
        position: relative;
        z-index: 3;
        padding-bottom: 0!important;
    }

    &__content {
        color: white;
        position: relative;
        z-index: 2;
        width: 100%;
        margin-left: -#{$spacing-xmedium};
        padding: 48px $spacing-xmedium;
        margin-top: -#{$container-mobile-top-padding};
        display: flex;
        flex-direction: column;
        
        &:before{
            content: "";
            @include full-size;
            display: block;
            background: $text-bg;
            left: 100px;
            z-index: 1;
        }

        @include breakpoint(medium) {
            max-width: 816px;
            margin-top: -#{$container-desktop-top-padding};
            padding: $spacing-xlarge $spacing-xlarge $spacing-xlarge $spacing-xmedium;
        }

        @include breakpoint(large) {
            margin-left: -#{$spacing-giga};
            padding-left: $spacing-xlarge;
        }
        

        @include breakpoint(extra-large) {
            margin-left: -#{$spacing-xlarge};
        }

        @include breakpoint(small) {
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    &__column {
        position: relative;
        z-index: 2;
        @include rtlAlignment;
        
        &:first-child {
            @include breakpoint(small) {
                padding-right: $spacing-extralarge;
            }
        }

        &:last-child {
            @include breakpoint(small) {
                min-width: 174px;
            }
        }
    }

    &__text {
        margin-bottom: 30px;

        @include breakpoint(ipad-pro) {
            margin-bottom: 50px;
        }
    }

    &__image-holder {
        z-index: 1;
        @include full-size;

        @include breakpoint(extra-large) {
           left:  $spacing-xlarge;
           width: calc(100% - 60px - ((100% - 1320px)/2));
        }

        @include breakpoint(full-hd) {
            left: 0;
            width: calc(100% - ((100% - 1320px)/2));
        }
    }

    &__slogan {
        white-space: nowrap;
        margin-top: 35px;

        @include breakpoint(ipad-pro) {
            margin-top: 70px;
        }
    }
}