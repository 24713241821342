.filter-heading {
    display: block;
    @include font-size(22px, 26px);
    font-weight: $font-weight-bold;
    margin-bottom: 18px;

    @include breakpoint(medium) {
        @include font-size(24px, 28px);
        margin-bottom: 32px;
    }
}