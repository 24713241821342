@import "../../shared-components-styles/page-hero-mixins.scss";

$text-width-without-paddings: calc(#{$max-width-s} - #{$spacing-xlarge}*2);
$max-image-width: 984px;
$max-scroll-width: 17px;

$hero-container-top-indent: 107px;
$hero-container-top-indent-mobile: 150px;

$hero-container-bottom-indent: $spacing-xlarge;

.short-article-page-hero {
    $self: &;
    position: relative;
    margin-bottom: $block-margin-mobile;

    @include breakpoint(medium) {
        margin-bottom: $block-margin-desktop;
    }

    &__all-species-link {
        margin-bottom: 48px;

        @include breakpoint(medium) {
            margin-bottom: $spacing-xlarge;
        }
    }

    &__heading {
        margin-bottom: 44px;

        @media all and (min-width: 840px) {
            width: calc(100% + (100vw - #{$max-width-s} - #{$spacing-xmedium} * 2)/2 - #{$max-scroll-width}/2 - #{$max-scroll-width}/2);
        }

        @include breakpoint(medium) {
            width: calc(#{$text-width-without-paddings} + #{$spacing-xlarge} + (100vw - #{$max-width-s} - #{$spacing-xlarge} * 2)/2 - #{$max-scroll-width}/2);
            margin-bottom: 68px;
        }

        @media all and (min-width: 1150px) {
            width: calc(#{$text-width-without-paddings} + (#{$max-image-width} - #{$text-width-without-paddings})/2);
        }
    }

    &__background {
        @include page-hero-background;
        @include page-hero-background-animated;
        background-color: $orange;
    }

    &__container {
        position: relative;
        @include container;
        @include page-hero-content-fade-animated;
        max-width: $max-bg-width;
        padding-top: $hero-container-top-indent-mobile;
        padding-bottom: $spacing-xlarge;

        @include breakpoint(medium) {
            padding-top: $hero-container-top-indent;
        }

        &_tall {
            padding-top: calc($hero-container-top-indent-mobile + $hero-container-top-indent-mobile * $page-hero-content-expansion-multiplier);
            padding-bottom: calc($hero-container-bottom-indent + $hero-container-bottom-indent * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                padding-top: calc($hero-container-top-indent + $hero-container-top-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__inner-container {
        margin: auto;
        max-width: $max-image-width;
    }

    &__image {
        &-holder {
            margin: 44px auto 0;
            max-width: $max-image-width;
            width: 100%;

            @include breakpoint(medium) {
                margin-top: $spacing-xlarge;
            }
        }

        &-caption {
            padding-left: 27%;
            @include font-size(16px, 24px);
            margin-top: $spacing-medium;

            @include breakpoint(medium) {
                padding: 0 30px;
                margin-top: 16px;
            }
        }
    }

    &__content {
        @include container;
        max-width: $max-width-s;
        padding-left: 0;
        padding-right: 0;
    }
}