@import "../../shared-components-styles/page-hero-mixins.scss";

$all-species-link-indent: 27px;
$all-species-link-indent-mobile: 32px;

$facts-indent: $spacing-xlarge;
$facts-indent-mobile: 30px;

.specimen-page-hero {
    $self: &;
    position: relative;
    display: flex;
    justify-content: center;
	margin-bottom: 66px;

    @include breakpoint(small) {
        margin-bottom: $spacing-xxlarge;
    }

    &__background {
        @include page-hero-background;
        @include page-hero-background-animated;
    }

    &__container {
        @include page-hero-container;
        transition: padding-bottom var(--page-hero-expansion-animation-duration);

        @include breakpoint(small) {
            flex-wrap: wrap;
        }

        &_tall {
            padding-bottom: calc($spacing-large + $spacing-large * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                padding-bottom: calc($spacing-xxlarge + $spacing-xxlarge * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__content {
        @include page-hero-content-column;
        @include page-hero-content-fade-animated;
        padding-top: $spacing-xlarge;

        @include breakpoint(small, max) {
            order: 1;
            padding-top: $spacing-large;
        }

        #{$self}__container_tall & {
            padding-top: calc($spacing-large + $spacing-large * $page-hero-content-expansion-multiplier);

            @include breakpoint(medium) {
                padding-top: calc($spacing-xlarge + $spacing-xlarge * $page-hero-content-expansion-multiplier);
            }
        }

        @include rtlAlignment;
    }

    &__image {
        position: absolute !important;
        top: 0;
        left: 0;

        &-holder {
            @include page-hero-image-column;
            margin-bottom: 0;

            @include breakpoint(small, max) {
                order: 2;
            }

            &-overlay {
                @include page-hero-image-overlay-animated;
                @include page-hero-expandable-overlay(648, 580, 440);
            }
        }

        &-centered-overlay {
            @include page-hero-centered-overlay(648, 580);
        }
    }

    &__heading {
        margin-bottom: 32px;
        word-break: break-word;

        @include breakpoint(small) {
            margin-bottom: 32px;
        }
    }

    &__intro {
        padding-left: $spacing-xmedium;
        max-width: 508px;

        @include breakpoint(ipad-pro) {
            padding-left: 68px;
        }
    }

    &__facts {
        width: 100%;
        margin-top: $facts-indent-mobile;
        @include page-hero-content-fade-animated;
        transition: margin-top var(--page-hero-expansion-animation-duration);

        @include breakpoint(small) {
            display: flex;
            margin-top: $facts-indent;
            padding-left: 4px;
        }

        #{$self}__container_tall & {
            margin-top: calc($facts-indent-mobile + $facts-indent-mobile * $page-hero-content-expansion-multiplier);

            @include breakpoint(small) {
                margin-top: calc($facts-indent + $facts-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }

    &__all-species-link {
        margin-bottom: $all-species-link-indent-mobile;
        align-self: flex-start;

        @include breakpoint(small) {
            margin-bottom: $all-species-link-indent;
        }

        #{$self}__container_tall & {
            margin-bottom: calc($all-species-link-indent-mobile + $all-species-link-indent-mobile * $page-hero-content-expansion-multiplier);

            @include breakpoint(small) {
                margin-bottom: calc($all-species-link-indent + $all-species-link-indent * $page-hero-content-expansion-multiplier);
            }
        }
    }
}

.specimen-page-hero-fact {
    padding-right: 6%;

    @include rtlAlignment {
        padding-left: 6%;
        padding-right: 0;
    }

    @include breakpoint(small, max) {
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }

    @include breakpoint(small) {
        margin-bottom: 0;

        &:first-child {
            width: 34%;
        }
    
        &:nth-child(2) {
            width: 33.6%;
        }
    
        &:nth-child(3) {
            width: 32.4%;
        }
    }

    &__heading {
        display: block;
        @include font-size(18px, 26px);
        margin-bottom: 8px;
    }
}