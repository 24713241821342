@import "../../shared-components-styles/rich-text-common.scss";

.rich-text {
  color: $primary-blue;
  @include font-size(20px, 28px);

  &>*:first-child {
    margin-top: 0;
  }

  &>*:last-child {
    margin-bottom: 0;
  }

  @include font-size(20px, 28px);

  ul {
    @include bullet-list-default;
  }

  ol {
    @include ordered-list-default;
  }

  img {
    max-width: 100%;
    height: auto !important;
  }

  a {
    @include underlined-link;
    font-weight: $font-weight-regular;

    &:hover,
    &:focus-visible {
      @include underlined-link-with-bg;
    }
  }

  blockquote,
  figure {
    margin: convert-to-rem(26px) 0 convert-to-rem(24px);

    @include breakpoint(medium) {
      margin: convert-to-rem(36px) 0 convert-to-rem(32px);
    }
  }

  blockquote {
    p {
      display: inline;
    }
  }

  table {
    background-color: $light-blue;
    border: 8px solid $light-blue;
    margin-top: 32px;
    margin-bottom: $block-margin-mobile;
    margin-left: -#{$spacing-xmedium};
    margin-right: -#{$spacing-xmedium};
    min-width: calc(100% + #{$spacing-xmedium}*2);

    @include breakpoint(small) {
      border: $spacing-xmedium solid $light-blue;
    }

    td {
      @include font-size(16px, 24px);

      @include breakpoint(medium) {
        @include font-size(20px, 28px);
      }
    }

    tr {

      td,
      th {
        padding: 8px;
        border: none;
        border-bottom: 1px solid $primary-blue;
        word-break: break-word;

        @include breakpoint(small) {
          padding: 16px;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &>tr:last-child {

      td,
      th {
        border-bottom: none;
      }
    }

    thead td,
    th {
      font-weight: 700;
      font-size: 18px;
      line-height: 1;


      @include breakpoint(small) {
        font-size: 18px;
        padding-bottom: 20px;
      }
    }

    @include breakpoint(medium) {
      margin-top: 48px;
      margin-bottom: $block-margin-desktop;
    }

    @include breakpoint(small) {
      min-width: 100vw;
      margin-left: calc((100vw - 100%)/2 * -1);
      margin-right: calc((100vw - 100%)/2 * -1);
    }

    @media all and (min-width: 1032px) {
      min-width: 1032px;
      max-width: 1032px;
      margin-left: calc((1032px - 100%)/2 * -1);
      margin-right: calc((1032px - 100%)/2 * -1);
    }

    &.cookie-table {      
      th, td {
        width: calc(100% / 3) !important;
      }
    }
  }

  &.rich-text_in-footer-column,
  &.rich-text_in-image-with-text-and-bg-color-block,
  &.rich-text_in-not-found-page,
  &.on-editing {
    table {
      min-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }


  &_in-footer-column {
    &.on-editing {
      background-color: $light-red;
    }

    p {
      margin-bottom: 0;
      min-height: 28px;
    }

    a {
      @include focus-outline;
      @include font-size(20px, 28px);
      font-weight: $font-weight-bold;
    }
  }

  &_in-recipe-page-body {
    ul {
      @include bullet-list;
    }

    ol {
      @include ordered-list;
    }
  }

  &_in-not-found-page {
    @include font-size(24px, 32px);

    ul {
      @include bullet-list;
      max-width: 560px;
      margin: 25px 0 36px 8px;

      @include breakpoint(medium) {
        margin: 36px 0 36px 32px;
      }

      li {
        &:before {
          background: $primary-blue;
        }
      }
    }

    h2 {
      @extend .h3;
      margin: 0 0 24px;

      @include breakpoint(medium) {
        margin: 0 0 36px;
      }
    }
  }
}