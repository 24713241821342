@import "../../shared-components-styles/footer-mixins.scss";
$footer-column-bottom-indent: 56px;

.footer {
    position: relative;
    overflow: hidden;

    @include breakpoint(1440px) {
        padding-left: 0;
    }

    &__background {
        @include footer-background;
    }

    &__container {
        @include footer-container;
        @include rtlAlignment;
    }

    &__heading {
        display: block;
        position: relative;
        @include font-size(36px, 40px);
        max-width: 752px;

        @include breakpoint(medium) {
            @include font-size(40px, 44px);
        }
    }

    &__columns {
        position: relative;
        margin-top: 55px;

        @include breakpoint(medium) {
            margin-top: 48px;
            margin-bottom: -$footer-column-bottom-indent;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__column {
        width: 100%;
        
        margin-bottom: $footer-column-bottom-indent;

        @include breakpoint(medium) {
            width: 50%;
            padding-right: $spacing-xmedium;

            @include rtlAlignment {
                padding-right: 0;
                padding-left: $spacing-xmedium;
            }
        }

        @include breakpoint(large) {
            width: 25%;

            &:first-child {
                width: 22%;        
            }
    
            &:nth-child(2),
            &:nth-child(3) {
                width: 26.5%;        
            }
    
            &:nth-child(3) {
                width: 26.5%;        
            }
        }

        &-heading {
            display: block;
            float: none;
            @include font-size(18px, 26px);
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            letter-spacing: 0.8px;
            margin: 0 0 16px;
        }
    }

    &__socials {
        &-list {
            display: flex;
            list-style: none;
            flex-wrap: wrap;
            gap: $spacing-medium $spacing-xmedium;
        }
    }

    a[href^="mailto:"],
    a[href^="tel:"] {
        display: inline-block;
        position: relative;
        max-width: calc(100% - 36px);
        margin: 0 36px;
        text-decoration: underline;
        box-shadow: none;
        word-wrap: break-word;
        transition: background-position 0.25s, color 0.25s, outline-offset 0.35s;

        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }

    a[href^="mailto:"] { 
        &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: -33px;
            display: inline-block;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMS4zMzM1IDFIMjIuNjY2OE0xLjMzMzUgMVYxN0gyMi42NjY4VjFNMS4zMzM1IDFMMTIuMDAwMiA5Ljg4ODg5TDIyLjY2NjggMSIgc3Ryb2tlPSIjMDAwMDRGIiBzdHJva2Utd2lkdGg9IjIiLz4NCjwvc3ZnPg0K");
            background-size: cover;
            width: 24px;
            height: 18px;

            @include rtlAlignment {
                left: auto;
                right: -33px;
            }
        }
    }

    a[href^="tel:"] { 
        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: -28px;
            display: inline-block;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cmVjdCB4PSIxLjMzMzMxIiB5PSIxLjMzMzMxIiB3aWR0aD0iMTQuMjIyMiIgaGVpZ2h0PSIyMS4zMzMzIiBzdHJva2U9IiMwMDAwNEYiIHN0cm9rZS13aWR0aD0iMiIvPg0KICAgIDxyZWN0IHg9IjYuNjY2NjkiIHk9IjE1LjU1NTYiIHdpZHRoPSIzLjU1NTU2IiBoZWlnaHQ9IjMuNTU1NTYiIHJ4PSIxLjc3Nzc4IiBmaWxsPSIjMDAwMDRGIi8+DQo8L3N2Zz4NCg==");
            background-size: cover;
            width: 16px;
            height: 23px;

            @include rtlAlignment {
                left: auto;
                right: -28px;
            }
        }
    }

    &__privacy {
        list-style: none;

        &-item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &-link {
            @include underlined-link;
            @include font-size(20px, 28px);
            font-weight: $font-weight-bold;

            &:hover,
            &:focus-visible {
                @include underlined-link-with-bg;
            }
        }
    }

    &__logo {
        &-holder {
            margin-top: 68px;

            @include breakpoint(medium) {
                margin-top: 48px;
            }
        }
    }
}