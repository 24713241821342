.article-teaser {
    $self: &;
    text-decoration: none;
    @include focus-outline;
    display: block;

    &:after {
        display: block;
        clear: both;
        content: '';
    }

    &_size_large {
        @include breakpoint(small) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    &__image {
        transform: scale(1.01);
        transition: transform 0.25s ease-in-out;

        #{$self}:hover &,
        #{$self}:focus-visible & {
            transform: scale(1.1);
        }

        &-holder {
            overflow: hidden;
            margin-bottom: 20px;

            #{$self}_size_large & {
                @include breakpoint(small) {
                    margin-bottom: 0; 
                    flex-basis: calc(50% - 12px);
                    max-width: calc(50% - 12px);
                }
            }
        }
    }

    &__content {
        #{$self}_size_large & {
            @include breakpoint(small) {
                flex-basis: calc(50% + 12px);
                max-width: calc(50% + 12px);
                padding-left: 24px;
            }

            @include breakpoint(medium) {
                padding-left: 54px;
            }
        }
    }

    &__heading {
        margin: 0 0 30px;
        word-break: break-word;
        float: left;
        font-weight: 700;

        @include rtlAlignment {
            float: right;
        }

        @include breakpoint(medium) {
            margin-bottom: 23px;

            #{$self}_size_large & {
                @include font-size(50px, 52px);
            }
        }

        &-text {
            @include underlined-link(2px);

            #{$self}:hover &,
            #{$self}:focus-visible &,
            #{$self}:active & {
                @include underlined-link-with-bg;
            }

            #{$self}_size_large & {
                @include breakpoint(medium) {
                    border-bottom-width: 4px;
                }
            }
        }
    }

    &__icon {
        width: 28px;
        height: 28px;
        margin-left: 0;
        transition: margin-left 0.25s;
        clear: both;
        display: block;
        float: left;

        @include rtlAlignment {
            float: right;
        }

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}:active & {
            margin-left: 32px;
        }

        #{$self}_size_large & {
            @include breakpoint(small) {
                width: 34px;
                height: 34px;
            }
        }
    }
}