@import "../../shared-components-styles/recipe-page.scss";

.ingredients {
    color: $primary-blue;
    @include rtlAlignment;

    &__heading {
        @extend h3;
        @include recipe-page-heading;
    }

    &__servings {
        height: $columns-top-padding;
        margin-top: -#{$columns-top-padding};

        &-panel {
            display: flex;
            flex-wrap: nowrap;
        }

        &-label,
        &-count {
            align-self: center;
        }

        &-label:after {
            content: ":";
        }

        &-label {
            margin-right: 12px;

            @include rtlAlignment {
                margin-left: 12px;
                margin-right: 0;
            }

            @include breakpoint(medium) {
                margin-right: 22px;

                @include rtlAlignment {
                    margin-left: 22px;
                    margin-right: 0;
                }
            }
        }

        &-count {
            width: 54px;
            height: 54px;
            border: 2px solid $primary-blue;
            color: $primary-blue;
            margin-right: 15px;
            text-align: center;
            @include font-size(22px, 26px);
            font-weight: 400;
            @include focus-outline;

            @include rtlAlignment {
                margin-left: 15px;
                margin-right: 0;
            }

            @include breakpoint(medium) {
                @include font-size(24px, 32px);
                width: 50px;
                height: 50px;
            }
        }

        &-buttons {
            display: flex;
            flex-direction: column;
            height: 104px;
            justify-content: space-between;

            @include breakpoint(medium) {
                height: 88px;
            }
        }

        &-button {
            width: 48px;
            height: 48px;
            @include focus-outline(5px);

            @include breakpoint(medium) {
                width: 40px;
                height: 40px;
            }
        }
    }



    &__section {
        border: none;
        padding: 0;
        @include font-size(20px, 28px);
        margin-bottom: 40px;

        @include breakpoint(medium) {
            margin-bottom: 55px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        &-heading {
            font-weight: 700;
            @include font-size(22px, 26px);
            margin-bottom: 18px;

            @include breakpoint(medium) {
                @include font-size(24px, 28px);
                margin-bottom: 20px;
            }
        }

        &-item {
            margin-bottom: 14px;

            &-content {
                @include rtlAlignment {
                    display: inline-flex;
                    flex-wrap: wrap;
                }
            }
        }

        &-item-amount {
            font-weight: 700;
            margin-right: 5px;
        }

        &-item-unit,
        &-item-name {
            margin-right: 5px;
        }
    }

}