
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-indicator {
  $self: &;
  @include full-size;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;

  &:before {
    content: '';
    @include full-size;
    opacity: 0.5;
    background-color: $white;
  }

  &__icon {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    z-index: 21;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 5px solid $primary-blue;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $primary-blue transparent transparent transparent;

      #{$self}_light & {
        border: 5px solid $white;
        border-color: $white transparent transparent transparent;
      }

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}