$item-bottom-indent: 20px;

.toggle-filter {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -#{$item-bottom-indent};

    &_disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &__item {
        margin-bottom: $item-bottom-indent;

        &:not(:last-child) {
            margin-right: 32px;

            @include rtlAlignment {
                margin-right: 0;
                margin-left: 32px;
            }
        }
    }
}