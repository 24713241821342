@import "../../shared-components-styles/article-page-mixins.scss";

.how-to-page {
    &__body {
        @include article-body;
        margin-top: 64px;
        margin-bottom: 64px;

        @include breakpoint(medium) {
            margin-bottom: $block-margin-desktop;
        }
    }
}
