$animation-speed: 0.25s;

.share-recipe {
    $self: &;
    margin: 0 0 58px;
    position: relative;
    z-index: 2;

    @include breakpoint(medium) {
        margin-bottom: 70px;
    }

    &__heading {
        margin: 0 0 36px;
        @include font-size(26px, 32px);
        font-weight: 400;

        @include breakpoint(medium) {
            margin: 0 0 45px;
            @include font-size(32px, 38px);
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 8px;

        @include breakpoint(small) {
            flex-direction: row;
        }
    }

    &__button {
        margin: 0;

        @include breakpoint(small) {
            margin: 0;
        }

        &_share {
            margin: 31px 0 0;
            order: 2;

            @include breakpoint(small) {
                margin: 0 40px 0 0;
                order: 1;

                @include rtlAlignment {
                    margin: 0 0 0 40px;
                }
            }
        }
    }

    &__copy-button-container {
        position: relative;
        order: 1;

        @include breakpoint(small) {
            order: 2;
        }
    }

    &__copy-confirmation {
        position: absolute;
        top: calc(100% + 13px);
        left: -#{$spacing-xmedium};
        width: 0;
        max-width: calc(100vw - 30px);
        transition: width $animation-speed ease-in-out;
        background: $light-green;
        overflow: hidden;

        &_active {
            width: 390px;
        }

        @include breakpoint(small) {
            left: -8px;
        }

        &-content {
            padding: 16px $spacing-xmedium;
            width: 390px;
            max-width: calc(100vw - 30px);
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
            transition-delay: 0s;

            #{$self}__copy-confirmation_active & {
                opacity: 1;
                transition-delay: $animation-speed;
            }

            @include breakpoint(medium) {
                padding: 16px;
            }
        }

        &-heading {
            display: flex;
            flex-direction: row;
            @include font-size(18px, 26px);
            font-weight: 700;

            &-text {
                align-self: center;
            }
        }

        &-icon {
            margin-right: 10px;
            align-self: center;

            @include rtlAlignment {
                margin-left: 10px;
                margin-right: 0;
            }
        }

        &-text {
            margin-top: 10px;
            @include font-size(20px, 28px);
        }
    }
}