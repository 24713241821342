.secondary-menu {
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin-bottom: $spacing-small;

        &:not(:last-child) {
            margin-right: $spacing-large;
        }
    }

    &__link {
        color: $white;
        font-weight: $font-weight-light;
        @include underlined-link(1px, $white);

        &:hover,
        &:focus-visible {
            @include underlined-link-with-bg($primary-blue);
        }
    }
}