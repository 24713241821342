.link-button {
    $self: &;
    display: inline-block;
    position: relative;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    padding-right: $spacing-xlarge;
    @include focus-outline;
    transition: all 350ms;

    &.link-button_small {
        padding-right: 48px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &_arrow-position-left {
        padding-left: $spacing-xlarge;
        padding-right: 0;
        margin-left: -8px;

        &.link-button_small {
            padding-right: 0;
            padding-left: 48px;
        }
    }

    @include breakpoint(medium) {
        &.link-button_small {
            padding-top: 1px;
            padding-bottom: 1px;
        }

        &_arrow-position-left {
            padding-left: $spacing-xlarge;
            padding-right: 0;
            margin-left: -8px;

            &.link-button_small {
                padding-left: 48px;
                padding-right: 0;
            }
        }
    }

    &_reversed-arrow-direction-on-hover:hover,
    &_active {
        padding-left: 50px;
        padding-right: 0;

        @include breakpoint(medium) {
            padding-left: $spacing-xlarge;
        }
    }

    &.species-quick-overview-block__specie-link {
        &.link-button_active {
            @include breakpoint(medium, max) {
                padding-left: 0;
                padding-right: 62px;
    
                #{$self}__arrow_prefix{
                    left: -40px;
                }
    
                #{$self}__arrow_suffix{
                    right: 8px;
                }
            }
        }
    }

    &__text {
        flex: 1;
        position: relative;
        padding: 2px 0;
        color: $primary-blue;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        @include font-size(28px, 40px);
        z-index: 1;
        transition: box-shadow 200ms;
        @include underlined-link(3px);

        #{$self}:hover &,
        #{$self}:focus-visible &,
        #{$self}_active & {
            @include underlined-link-with-bg;
        }

        @include breakpoint(medium) {
            @include font-size(32px, 46px);
        }

        #{$self}_small & {
            @include underlined-link(2px);
            font-size: 20px !important;
        }

        &_theme-light {
            @include underlined-link(3px, $white);

            #{$self}_small & {
                @include underlined-link(2px, $white);
            }

            #{$self}:hover &,
            #{$self}:focus-visible &,
            #{$self}_active & {
                @include underlined-link-with-bg($primary-blue);
            }
        }

        .species-quick-overview-block__specie-link:hover & {
            @include breakpoint(medium, max) {
                box-shadow: none;
            }
        }

        .species-quick-overview-block__specie-link & {
            @include breakpoint(small-max, max) {
                @include font-size(36px, 47px);
            }

            @include breakpoint(medium, max) {
                border-bottom: none;
            }
        }

        .species-quick-overview-block__specie-link.link-button_active & {
            @include breakpoint(medium, max) {
                color: $maincolor !important;
                background: none;
                box-shadow: inset 0 -2px 0 $primary-blue, 0 0px 0 $primary-blue !important;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        width: 28px;
        height: 23px;
        margin-top: -12px;
        display: inline-block;
        vertical-align: text-bottom;
        color: $primary-blue;
        transition: all 350ms;

        #{$self}_small & {
            width: 18px;
            height: 17px;
            margin-top: -8px;

            @include breakpoint(medium) {
                width: 23px;
                height: 22px;
                margin-top: -11px;
            }
        }

        @include breakpoint(medium) {
            margin-top: -14px;
            width: 33px;
            height: 28px;
        }

        &_prefix {
            left: -40px;

            #{$self}_reversed-arrow-direction-on-hover:hover &,
            #{$self}_active & {
                left: 0;
            }

            #{$self}_reversed-arrow-direction-on-hover:hover:active & {
                left: 12px;
            }
        }

        &_suffix {
            right: 8px;

            #{$self}:hover & {
                right: 4px;
            }

            #{$self}:active &,
            #{$self}_active & {
                right: 0;
            }

            #{$self}_reversed-arrow-direction-on-hover:hover & {
                right: -56px;
            }

            #{$self}_arrow-position-left & {
                right: -40px;
            }
        }

        #{$self}_theme-light & {
            color: $white;
        }
    }

    &.link-button_arrow-position-left {
        #{$self}__arrow_prefix {
            left: 8px;
        }

        &:hover {
            #{$self}__arrow_prefix {
                left: 4px;
            }
        }

        &:active {
            #{$self}__arrow_prefix {
                left: 0;
            } 
        }
    }
}