.specimen-page {
    $self: &;

    &__body {
        @extend .container;
        max-width: $max-width-l;
        margin-top: $block-margin-mobile;
        margin-bottom: $block-margin-mobile;
        padding-left: $spacing-xmedium;
        padding-right: $spacing-xmedium;
        @include rtlAlignment;

        @include breakpoint(small) {
            display: flex;
            justify-content: center;
            padding-left: $spacing-xmedium * 2;
            padding-right: $spacing-xmedium * 2;
        }

        @include breakpoint(medium) {
            margin-top: $spacing-extralarge;
            margin-bottom: $spacing-extralarge;
            padding-left: $spacing-giga;
            padding-right: $spacing-giga;
        }

        &-column {
            @include breakpoint(small) {
                padding-right: 68px;

                @include rtlAlignment {
                    padding-left: 68px;
                    padding-right: 0;
                }

                #{$self}__body_single-column & {
                    max-width: 650px;
                    width: auto !important;
                    padding-right: 0;
                }
            }

            @include breakpoint(small, max) {
                &:not(:last-child) {
                    margin-bottom: 44px;
                }
            }

            @include breakpoint(small) {
                &:first-child {
                    width: 51%;
                }
    
                &:last-child {
                    width: 49%;
                }
            }
        }
    }
}